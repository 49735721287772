import { PropsWithChildren } from "react"
import s from "./CompanyPicker.module.scss"

export const CompanyPicker = ({ children }: PropsWithChildren) => (
  <div className={s.menu}>{children}</div>
)

const Companies = ({ children }: PropsWithChildren) => (
  <div className={s.menu__companies}>{children}</div>
)

type BottomProps = {
  onAddCompany: () => void
  addButtonText: string
}
const Bottom = ({ onAddCompany, addButtonText }: BottomProps) => (
  <div className={s.menu__bottom}>
    <button className={s.menu__add} onClick={onAddCompany}>
      {addButtonText}
    </button>
  </div>
)

type CompanyProps = {
  companyName: string
  companyLogo: string
  href: string
}
const Company = ({ companyLogo, companyName, href }: CompanyProps) => (
  <a className={s.menu__company} title={companyName} href={href}>
    <img className={s.menu__logo} src={companyLogo} alt="" />
  </a>
)

CompanyPicker.Companies = Companies
CompanyPicker.Bottom = Bottom
CompanyPicker.Company = Company
