import { useMatch } from "@tanstack/react-location"
import { useQueryAll } from "bonzai"
import { useModal } from "hooks"
import { custom, v1 } from "src/bonzai/bonzai"
import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { ConfigError } from "src/tools/ConfigError"
import { useIframeMessages } from "src/tools/useIframeMessages"
import { Loader, ViewerModal } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type ProductType = v1["getProduct"]["product_type"]

type WebGLModalLoaderProps = {
  closeRoute: string
}
export const WebGLModalLoader = ({ closeRoute }: WebGLModalLoaderProps) => {
  const productId = Number(useMatch().params.productId)

  const { onClose } = useModal(closeRoute, () => {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  })

  return (
    <ViewerModal onClose={onClose}>
      <QueryBoundary fallback={<Loader />}>
        <Load productId={productId} onClose={onClose} />
      </QueryBoundary>
    </ViewerModal>
  )
}

type LoadProps = {
  productId: number
  onClose: () => void
}
const Load = ({ productId, onClose }: LoadProps) => {
  const [product, links] = useData(productId)

  useIframeMessages({
    onClose,
    targetUrl: product.content_link,
  })

  const { webGLLink } = links
  if (!webGLLink) {
    throw new ConfigError(`No webGLLink for product ${productId}`)
  }

  return (
    <ViewerModal.WebGL
      src={webGLLink}
      orientation={getProductOrientation(product.product_type)}
      onClose={onClose}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    v1.getProduct.useQuery([productId]),
    custom.getPlayLinks.useQuery([productId]),
  ])
}

const getProductOrientation = (type: ProductType) => {
  if (type === "skill") return "portrait"

  return "landscape"
}
