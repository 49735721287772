import {
  array,
  assign,
  boolean,
  enums,
  lazy,
  literal,
  nullable,
  number,
  optional,
  record,
  string,
  Struct,
  type,
} from "superstruct"
import { PortalConfigurationFlag, ProductType } from "../v1/v1Responses"

export const Platform = enums([
  "iOS",
  "Android",
  "Windows",
  "MacOS",
  "WebGL",
] as const)

export const Links = type({
  first: string(),
  last: string(),
  next: nullable(string()),
  prev: nullable(string()),
  self: string(),
})

export const Locale = type({
  code: string(),
  name: string(),
})

export const TranslatedTexts = record(string(), nullable(string()))

export const User = type({
  first_name: string(),
  last_name: string(),
  email: string(),
  username: string(),
})

export const UserResponse = type({
  data: User,
})

export const Company = type({
  id: string(),
  background_url: string(),
  logo_url: string(),
  name: string(),
  name_key: string(),
  subdomain: string(),
})

export const CompanyResponse = type({
  data: Company,
})

export const LandingPage = enums([
  "for_you",
  "journey",
  "leaderboards",
  "explore",
  "news",
] as const)

export const CompanySettings = type({
  id: string(),
  default_locale: Locale,
  default_portal_landing_page: LandingPage,
  portal_background_url: nullable(string()),
  portal_configurations: array(PortalConfigurationFlag),
  portal_supported_locales: array(Locale),
  supported_configurations: array(enums(["portal", "adminview"] as const)),
  supported_locales: array(Locale),
  user_display_name_format: enums([
    "username",
    "firstname",
    "firstname_and_lastname",
  ] as const),
})

export const CompanySettingsResponse = type({
  data: CompanySettings,
})

export const NewsPostReaction = type({
  id: string(),
  user_id: number(),
  reaction_type: literal("like"),
})

export const NewsPost = type({
  id: string(),
  body: TranslatedTexts,
  updated_at: string(),
  headline: TranslatedTexts,
  is_liked: boolean(),
  likes_count: number(),
  image: optional(
    type({
      file_url: string(),
    })
  ),
  reactions: optional(array(NewsPostReaction)),
})

export const NewsPostsResponse = type({
  data: array(NewsPost),
})

export const NewsPostResponse = type({
  data: NewsPost,
})

export const CreateNewsPostReactionResponse = type({
  data: NewsPostReaction,
})

export const ActivityItem = type({
  done: boolean(),
  id: string(),
  title: TranslatedTexts,
})

export const ActivityItemsResponse = type({
  data: array(ActivityItem),
})

export const ActivityProgressResponse = type({
  data: type({
    progress_count: number(),
    progress_of: number(),
  }),
})

export const UserProductResponse = type({
  data: type({
    mandatory: boolean(),
    stars_requirement: number(),
  }),
})

export const UpdateActivityItemResponse = type({
  data: type({
    activity_item_id: number(),
    done: boolean(),
    user_id: number(),
  }),
})

export const OathApplication = type({
  id: string(),
  api_token: string(),
  platform: Platform,
})

export const ProductCourse = type({
  id: string(),
  attendees_count: number(),
  attendees_count_max: number(),
  waiting_list_count: number(),
  waiting_list_position: number(),
  is_attending: boolean(),
  is_on_waiting_list: boolean(),
  cancelled: boolean(),
  created_at: string(),
  end_datetime: string(),
  instructor: string(),
  location: string(),
  room: string(),
  start_datetime: string(),
  updated_at: string(),
})

export const Product = type({
  apple_store_link: string(),
  certification_requirement: number(),
  company_id: number(),
  content_link: string(),
  description_long: TranslatedTexts,
  description_short: TranslatedTexts,
  google_play_link: string(),
  google_play_test_link: nullable(string()),
  id: string(),
  manager_approval: boolean(),
  no_score: boolean(),
  picker_app: boolean(),
  product_image_url: string(),
  product_type: type({
    description: TranslatedTexts,
    identifier: ProductType,
    open_with: enums(["launcher", "href"] as const),
  }),
  self_serve: boolean(),
  supported_platforms: array(Platform),
  test_flight_link: nullable(string()),
  title: TranslatedTexts,
  token: nullable(string()),
  webgl_responsive: boolean(),
  oauth_applications: optional(array(OathApplication)),
  courses: optional(array(ProductCourse)),
  company: optional(Company),
})

export const ProductResponse = type({
  data: Product,
})

export const ProductProgress = type({
  certified: boolean(),
  stars: number(),
})

export const ProductProgressResponse = type({
  data: ProductProgress,
})

type GroupType = {
  id: string
  name: string
  children?: GroupType[]
}
export const Group: Struct<GroupType> = lazy(() =>
  type({
    id: string(),
    name: string(),
    children: optional(array(Group)),
  })
)

export const ProductCourseResponse = type({
  data: assign(
    ProductCourse,
    type({
      group: optional(Group),
    })
  ),
})

export const GroupResponse = type({
  data: Group,
})

export const ProductCategoryProgressResponse = type({
  data: type({
    certified_count: number(),
    certified_max: number(),
    stars_count: number(),
    stars_max: number(),
  }),
})

export const KpiSalesData = type({
  year_count: number(),
  update_at: string(),
  current_month_count: type({
    timestamp: string(),
    value: number(),
  }),
  months: array(
    type({
      timestamp: string(),
      value: number(),
    })
  ),
})

export const KpiSalesResponse = type({
  data: nullable(KpiSalesData),
})

export const ProductsResponse = type({
  data: array(Product),
  links: Links,
})

export const Department = type({
  id: string(),
  name: string(),
  company_id: number(),
  parent_id: nullable(number()),
  created_at: string(),
  updated_at: string(),
})

export const DepartmentsResponse = type({
  data: array(Department),
  links: Links,
})
