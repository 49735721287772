import { createPlaythrough } from "src/bonzai/createPlaythrough"
import { useVuplexStore } from "src/stores/useVuplexStore"
import { assert } from "superstruct"
import { IncomingMessage } from "./IncomingMessage"

const { setProductPlayingId } = useVuplexStore.getState()

export const vuplexMessageListener = (data: string) => {
  const message = parseIncomingMessage(data)

  switch (message.type) {
    case "SET_MINI_MODAL_PRODUCT_ID":
      return setProductPlayingId(message.payload)

    case "REFRESH_PRODUCT_SCORE":
      return refreshProductScore()
  }
}

const parseIncomingMessage = (data: string) => {
  const message = JSON.parse(data)
  assert(message, IncomingMessage)
  return message
}

const refreshProductScore = () => {
  const productId = useVuplexStore.getState().productPlayingId
  if (productId) {
    createPlaythrough(productId, {
      mutate: false,
      invalidate: true,
      invalidateDependencies: true,
    })
  }
}
