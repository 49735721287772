import { FetchQuery, getFetchQuery } from "./fetchQuery"
import { getGetQueryData, GetQueryData } from "./getQueryData"
import { getInvalidateQueries, InvalidateQueries } from "./invalidateQueries"
import { getPrefetchQuery, PrefetchQuery } from "./prefetchQuery"
import { getSetQueriesData, SetQueriesData } from "./setQueriesData"
import { getSetQueryData, SetQueryData } from "./setQueryData"
import { BonzaiProps, FnBase, FunctionsBase } from "./types"
import { getUseInfiniteQuery, UseInfiniteQuery } from "./useInfiniteQuery"
import { getUseMutation, UseMutation } from "./useMutation"
import { getUseQueries, UseQueries } from "./useQueries"
import { getUseQuery, UseQuery } from "./useQuery"
import { getQueryPrefix } from "./utils/getQueryPrefix"

type Methods<Fn extends FnBase> = {
  fetchQuery: FetchQuery<Fn>
  getQueryData: GetQueryData<Fn>
  invalidateQueries: InvalidateQueries<Fn>
  prefetchQuery: PrefetchQuery<Fn>
  setQueriesData: SetQueriesData<Fn>
  setQueryData: SetQueryData<Fn>
  useInfiniteQuery: UseInfiniteQuery<Fn>
  useMutation: UseMutation<Fn>
  useQuery: UseQuery<Fn>
  useQueries: UseQueries<Fn>
}

type BonzaiReturn<Functions extends FunctionsBase> = {
  [P in keyof Functions]: Methods<Functions[P]>
}

export const bonzai = <Functions extends FunctionsBase>(
  props: BonzaiProps<Functions>
): BonzaiReturn<Functions> => {
  const queryPrefix = getQueryPrefix()

  const get = (_: any, functionName: string): Methods<any> => {
    return {
      fetchQuery: getFetchQuery(props, queryPrefix, functionName),
      getQueryData: getGetQueryData(props, queryPrefix, functionName),
      invalidateQueries: getInvalidateQueries(props, queryPrefix, functionName),
      prefetchQuery: getPrefetchQuery(props, queryPrefix, functionName),
      setQueriesData: getSetQueriesData(props, queryPrefix, functionName),
      setQueryData: getSetQueryData(props, queryPrefix, functionName),
      useInfiniteQuery: getUseInfiniteQuery(props, queryPrefix, functionName),
      useMutation: getUseMutation(props, functionName),
      useQuery: getUseQuery(props, queryPrefix, functionName),
      useQueries: getUseQueries(props, queryPrefix, functionName),
    }
  }

  return new Proxy({}, { get })
}
