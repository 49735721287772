import { apiV1, apiV3 } from "api"
import { bonzai, InferBonzai } from "bonzai"
import { authApi, createQueryClient } from "tools"
import * as customApi from "../customApi"

export const queryClient = createQueryClient()

export type v1 = InferBonzai<typeof v1>
export const v1 = bonzai({
  functions: apiV1,
  queryClient: queryClient,
})

export type v3 = InferBonzai<typeof v3>
export const v3 = bonzai({
  functions: apiV3,
  queryClient: queryClient,
})

export type auth = InferBonzai<typeof auth>
export const auth = bonzai({
  functions: authApi,
  queryClient: queryClient,
})

export type custom = InferBonzai<typeof custom>
export const custom = bonzai({
  functions: customApi,
  queryClient: queryClient,
})
