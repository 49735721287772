import { useNavigate, useSearch } from "@tanstack/react-location"
import { useDisableDocumentScroll } from "./useDisableDocumentScroll"
import { useEvent } from "./useEvent"
import { useOnEscape } from "./useOnEscape"

export const useModal = (closeRoute: string, onClose?: () => void) => {
  const navigate = useNavigate()
  const search = useSearch()

  const closeModal = useEvent(() => {
    onClose?.()

    navigate({
      to: closeRoute,
      search: search,
    })
  })

  useOnEscape(closeModal)

  useDisableDocumentScroll() // Not sure about this one. It repositions content and background image

  return { onClose: closeModal }
}
