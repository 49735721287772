import { SetDataOptions } from "@tanstack/react-query"
import produce from "immer"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = SetDataOptions
type Return<Fn extends FnBase> = FnReturn<Fn> | undefined
type Updater<Fn extends FnBase> =
  | FnReturn<Fn>
  | ((oldData: FnReturn<Fn> | undefined) => FnReturn<Fn> | undefined | void)

export type SetQueryData<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (
        args: Parameters<Fn>,
        updater: Updater<Fn>,
        options?: Options
      ): Return<Fn>
      (updater: Updater<Fn>, options?: Options): Return<Fn>
    }
  : (
      args: Parameters<Fn>,
      updater: Updater<Fn>,
      options?: Options
    ) => Return<Fn>

export const getSetQueryData = <Functions extends FunctionsBase>(
  { queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): SetQueryData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const [updater, options] = rest

    const key = [queryPrefix, functionName, ...args]

    return queryClient.setQueryData(
      key,
      typeof updater === "function" ? produce(updater) : updater,
      options
    )
  }
}
