import { useTranslation } from "react-i18next"
import { v1 } from "src/bonzai/bonzai"
import { UserInfoHeader } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const UserInfoHeaderLoader = () => (
  <QueryBoundary fallback={<UserInfoHeader.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const userStatus = v1.getUserStatus.useQuery()
  const { t } = useTranslation()

  return (
    <UserInfoHeader>
      <UserInfoHeader.Completed
        label={t("user.COMPLETED")}
        value={userStatus.certified.count}
        maxValue={userStatus.certified.of}
        link={{ to: "/for-you", search: { incomplete: true } }}
      />
      <UserInfoHeader.Stars
        value={userStatus.stars.count}
        maxValue={userStatus.stars.of}
      />
      <UserInfoHeader.Mandatory
        label={t("user.MANDATORY")}
        value={userStatus.mandatory.stars_count}
        maxValue={userStatus.mandatory.stars_of}
        link={{ to: "/for-you", search: { mandatory: true } }}
      />
      <UserInfoHeader.Progress
        value={userStatus.certified.count}
        maxValue={userStatus.certified.of}
      />
    </UserInfoHeader>
  )
}
