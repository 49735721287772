import journeyIllustration from "assets/illustrations/journeyIllustration.svg"
import { PropsWithChildren } from "react"
import s from "./JourneyView.module.scss"

export const JourneyView = ({ children }: PropsWithChildren) => (
  <div className={s.view}>{children}</div>
)

type EmptyProps = {
  description: string
}
const Empty = ({ description }: EmptyProps) => (
  <div className={s.empty}>
    <img src={journeyIllustration} className={s.empty__illustration} alt="" />
    <div className={s.empty__text}>{description}</div>
  </div>
)

JourneyView.Empty = Empty
