import { useQueryAll } from "bonzai"
import { useToggle } from "hooks"
import { useTranslation } from "react-i18next"
import { v1, v3 } from "src/bonzai/bonzai"
import { TEXT_FALLBACK } from "src/constants"
import { Journey, Loader } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { JourneyCardLoader } from "./JourneyCardLoader"

type Category = v1["getProductCategories"]["data"][number]

type JourneyLoaderProps = {
  journey: Category
}
export const JourneyLoader = ({ journey }: JourneyLoaderProps) => {
  const [products, progress] = useData(journey.id)

  const { certified_count, certified_max } = progress
  const isIncomplete = certified_count < certified_max

  const [isOpen, toggleIsOpen] = useToggle(isIncomplete)

  const journeyCards = products.map((product) => (
    <JourneyCardLoader key={product.id} product={product} />
  ))

  return (
    <Journey backgroundImage={journey.background_image_url ?? ""}>
      <Journey.Header
        title={journey.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
        progress={certified_count}
        progressMax={certified_max}
        isOpen={isOpen}
        onClick={toggleIsOpen}
        isCertified={certified_count === certified_max}
      />
      <QueryBoundary fallback={<Loader />}>
        <Journey.Products isOpen={isOpen}>{journeyCards}</Journey.Products>
      </QueryBoundary>
    </Journey>
  )
}

const useData = (journeyId: number) => {
  const { i18n } = useTranslation()

  return useQueryAll(() => [
    v1.getProductsInCategory.useQuery(
      [{ category_id: journeyId, locale: i18n.language, per_page: 20 }],
      { select: (res) => res.data }
    ),

    v3.getProductCategoryProgress.useQuery([journeyId], {
      select: (res) => res.data,
    }),
  ])
}
