import { assert, Struct } from "superstruct"
import { createQueryClient } from "tools"
import { wrapFetch } from "wrap-fetch"
import { config } from "./config"
import { LOKALISE_URL } from "./constants"

const queryClient = createQueryClient()

export const getLokaliseFile = async <T>(
  schema: Struct<T>,
  fileName: string
) => {
  const { project } = await config
  const path = `/${project}/${fileName}`

  return queryClient.fetchQuery({
    queryKey: [fileName],
    queryFn: () => get(schema, path),
    staleTime: Number.POSITIVE_INFINITY,
  })
}

const get = <T>(schema: Struct<T>, path: string) => {
  return wrapFetch({
    path,
    baseUrl: LOKALISE_URL,
    transform: (response) => validateRequest(schema, response),
  })
}

const validateRequest = async <T>(schema: Struct<T>, response: string) => {
  const data = await JSON.parse(response)
  assert(data, schema)
  return data
}
