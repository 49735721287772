import { ReactNode } from "react"
import s from "./Modal.module.scss"

type ModalProps = {
  children: ReactNode
  onClose: () => void
}
export const Modal = ({ onClose, children }: ModalProps) => (
  <div className={s.modal}>
    <div className={s.modal__dimmer} onClick={onClose} />
    <div className={s.modal__content}>{children}</div>
  </div>
)
