import { MateriaBorderRadius } from "../types/MateriaBorderRadius"
import { MateriaColor } from "../types/MateriaColor"
import { MateriaSize } from "../types/MateriaSize"

export const getCssColor = (color: MateriaColor | undefined) => {
  if (color === undefined) return undefined
  return `var(--color-${color})`
}

export const getCssSize = (size: MateriaSize) => {
  return `var(--size-${size})`
}

export const getCssBorderRadius = (radius: MateriaBorderRadius | undefined) => {
  if (radius === undefined) return undefined
  return `var(--border-radius-${radius})`
}
