import {
  Link,
  LinkProps,
  useLoadRoute,
  useSearch,
} from "@tanstack/react-location"
import { useEvent } from "hooks"
import { AnchorHTMLAttributes } from "react"

export type FiestaLinkProps = ExternalLinkProps | InternalLinkProps

export const FiestaLink = (props: FiestaLinkProps) => {
  return props.href !== undefined ? (
    <ExternalLink {...props} />
  ) : (
    <InternalLink {...props} />
  )
}

type ExternalLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string
}
const ExternalLink = (props: ExternalLinkProps) => (
  <a {...props}>{props.children}</a>
)

type InternalLinkProps = LinkProps & {
  prefetch?: boolean
  href?: undefined
}
const InternalLink = ({ prefetch, ...props }: InternalLinkProps) => {
  const searchParams = useSearch()
  const loadRoute = useLoadRoute()

  const { to, search = searchParams } = props

  const onMouseEnter = useEvent(() => {
    if (!prefetch) return

    loadRoute({ to, search })
  })

  return <Link {...props} search={search} onMouseEnter={onMouseEnter} />
}
