import { useWindowWidth } from "hooks"
import { ReactNode } from "react"
import { getChromaticProps } from "../../../utils/getChromaticProps"
import s from "./WidgetLayout.module.scss"

type WidgetLayoutProps = {
  widgets: ReactNode
  children: ReactNode
  chromaticIgnore?: boolean
}
export const WidgetLayout = ({
  children,
  widgets,
  chromaticIgnore,
}: WidgetLayoutProps) => {
  const { isBigWindow } = useWindowWidth()

  return (
    <div className={s.widgetLayout}>
      <div className={s.widgetLayout__content}>{children}</div>
      {isBigWindow && (
        <aside
          className={s.widgetLayout__widgets}
          {...getChromaticProps(chromaticIgnore)}
        >
          {widgets}
        </aside>
      )}
    </div>
  )
}
