import i18next from "i18next"
import { authApi } from "tools"
import { loadLocales } from "./loadLocales"
import { saveLocaleInStorage } from "./localeStorage"

export const changeLocale = async (locale: string) => {
  const { companyNameKey } = await authApi.getActiveSession()

  saveLocaleInStorage(companyNameKey, locale)
  loadLocales([locale])
  i18next.changeLanguage(locale)
}
