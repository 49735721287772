import { useQueryAll } from "bonzai"
import { useTranslation } from "react-i18next"
import { custom, v3 } from "src/bonzai/bonzai"
import {
  formatEventDate,
  getHasEventExpired,
  pickEventDate,
} from "src/dataUtilities/eventDataUtilities"
import { getProductLink } from "src/dataUtilities/productDataUtilities"
import { ProductCardEvent } from "ui/exports/portal"

type ProductCardEventLoaderProps = {
  productId: number
  productTitle: string
}
export const ProductCardEventLoader = ({
  productId,
  productTitle,
}: ProductCardEventLoaderProps) => {
  const { t, i18n } = useTranslation()

  const [status, dates] = useData(productId)

  const event = pickEventDate(dates)
  const formattedDate = event ? formatEventDate(event, i18n.language) : null

  const getInfoText = () => {
    if (event === undefined) return t("event.NO_DATES_AVAILABLE")
    if (event.is_attending) return t("event.ATTENDING")
    if (status?.certified) return t("event.COMPLETED")
    return t("product.PRODUCT_TYPES.meet")
  }

  return (
    <ProductCardEvent
      showDate={formattedDate !== undefined}
      day={formattedDate?.day}
      month={formattedDate?.month}
      isAttending={event?.is_attending ?? false}
      link={{ to: getProductLink(productId, "meet") }}
      isCertified={status?.certified ?? false}
      isMandatory={status?.is_mandatory ?? false}
      title={productTitle}
      info={getInfoText()}
      mandatoryText={t("user.MANDATORY")}
    />
  )
}

const useData = (productId: number) => {
  return useQueryAll(() => [
    custom.getProductProgress.useQuery([productId, { apiVersion: "v1" }], {
      suspense: false,
    }),

    v3.getProduct.useQuery([productId, { include: ["courses"] }], {
      select: (res) =>
        res.data.courses?.filter((date) => !getHasEventExpired(date)) ?? [],
    }),
  ])
}
