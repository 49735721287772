import { combinePaths, queryParams } from "@attensi/utils"
import { createFetchError } from "./createFetchError"
import { isFetchError } from "./isFetchError"
import { FetchError, Headers, QueryParams } from "./types"

export type WrapFetchProps<T> = {
  path: string
  pathPrefix?: string
  baseUrl?: string
  method?: "GET" | "POST" | "DELETE" | "PATCH"
  headers?: Headers
  data?: unknown
  params?: QueryParams
  onError?: (error: FetchError) => any
  transform: (responseBody: string) => Promise<T> | T
}

export const wrapFetch = async <T>(args: WrapFetchProps<T>): Promise<T> => {
  const {
    path,
    pathPrefix,
    baseUrl,
    data,
    params,
    onError,
    method = "GET",
    transform,
  } = args

  let url = combinePaths(baseUrl, pathPrefix, path)
  if (params) url = queryParams(url, params)

  let response: Response | undefined
  let responseText: string | undefined

  try {
    const req = new Request(url, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: await mergeHeaders(args),
    })

    response = await fetch(req)
    if (!response.ok) throw response

    responseText = await response.text()

    return await transform(responseText)
  } catch (error) {
    const resolved = await resolveError({
      error,
      method,
      url,
      response,
      responseText,
    })
    await onError?.(resolved)
    throw resolved
  }
}

const mergeHeaders = async <T>(args: WrapFetchProps<T>) => {
  return {
    ...(args.data ? { "Content-Type": "application/json" } : undefined),
    ...args.headers,
  }
}

type ResolveErrorProps = {
  error: unknown
  method: string
  url: string
  response: Response | undefined
  responseText: string | undefined
}

const resolveError = async ({
  error,
  method,
  url,
  response,
  responseText,
}: ResolveErrorProps): Promise<FetchError> => {
  if (isFetchError(error)) {
    return error
  }

  if (error instanceof Response) {
    return createFetchError({
      url,
      method,
      message: "HTTP status code " + error.status,
      text: await error.text(),
      status: error.status,
      cause: undefined,
    })
  }

  if (error instanceof Error) {
    return createFetchError({
      url,
      method,
      message: error.message,
      cause: error,
      status: response?.status,
      text: responseText,
    })
  }

  throw new Error("Unrecognized error " + error)
}
