/// <reference path="deserialize-json-api.d.ts"/>
import { deserialize } from "deserialize-json-api"
import { assert, Struct } from "superstruct"
import { QueryParams, wrapFetch, WrapFetchProps } from "wrap-fetch"
import { getConfig } from "../apiConfig"

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return v3Fetch(schema, { method: "GET", path, params })
}

export const post = <T>(schema: Struct<T>, path: string, attributes?: any) => {
  const data = { data: { attributes } }
  return v3Fetch(schema, { method: "POST", path, data })
}

export const del = <T>(schema: Struct<T>, path: string) => {
  return v3Fetch(schema, { method: "DELETE", path })
}

const v3Fetch = async <T>(
  schema: Struct<T>,
  options: Omit<WrapFetchProps<T>, "transform" | "pathPrefix" | "headers">
) => {
  const { getHeaders, apiBaseUrl, onError } = await getConfig()

  return wrapFetch({
    pathPrefix: "/api/v3",
    transform: (response) => transformResponse(schema, response),
    baseUrl: apiBaseUrl,
    onError,
    headers: {
      accept: "application/vnd.api+json",
      ...(await getHeaders?.()),
    },
    ...options,
  })
}

const transformResponse = async <T>(schema: Struct<T>, response: string) => {
  const data = await JSON.parse(response)

  const deserialized = safeDeserialize(data)
  assert(deserialized, schema)
  return deserialized
}

const safeDeserialize = (value: unknown) => {
  try {
    return deserialize(value)
  } catch {
    return value
  }
}
