import { RefObject, useEffect, useRef } from "react"
import { useOnEscape } from "./useOnEscape"
import { useToggle } from "./useToggle"

export const useMenu = () => {
  const [isOpen, toggleMenu, closeMenu] = useToggle(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useOnEscape(closeMenu)
  useOnClickedOutside(containerRef, closeMenu)

  return { isOpen, toggleMenu, containerRef }
}

const useOnClickedOutside = <T extends HTMLElement>(
  insideRef: RefObject<T>,
  callback: () => void
) => {
  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      const element = insideRef.current
      if (!element) return

      const isInside = element.contains(e.target as Node)
      if (!isInside) {
        callback()
      }
    }

    document.addEventListener("click", onDocumentClick)
    return () => {
      document.removeEventListener("click", onDocumentClick)
    }
  }, [insideRef, callback])
}
