import * as authApiProd from "@attensi/auth"
import productImage from "assets/demo/productImage.svg"
import { mock } from "./mock"

const notMockable = () => {
  throw new Error("Non mockable")
}

const authApiMock = mock(authApiProd, (mocks) => ({
  getActiveSession: () => ({
    accessToken: "",
    companyNameKey: "test",
    logo: productImage,
    subdomain: "test",
    userId: 0,
    pickedLocale: "en-US",
  }),

  getLinkWithActiveSession: () => "",
  getSessions: () => [mocks.getActiveSession()],
  getSubdomainFromPath: () => "",
  initializeAuth: (): undefined => undefined,
  logoutAndGetNext: () => mocks.getActiveSession(),

  redirectWithActiveSession: notMockable,
  loginNewSession: notMockable,
  logoutAndLogin: notMockable,
  refreshLogin: notMockable,
}))

export const authApi =
  import.meta.env.MODE === "test" ? authApiMock.mocks : authApiProd
