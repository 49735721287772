import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import featuredArrowIcon from "assets/icons/featuredArrowIcon.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { Children, PropsWithChildren, ReactNode, useState } from "react"
import { modifiers } from "tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { backgroundImageStyle } from "../../../utils/backgroundImageStyle"
import { Card } from "../../base/Card"
import s from "./FeaturedProducts.module.scss"

export const FeaturedProducts = ({ children }: PropsWithChildren) => {
  const { numberOfProducts, index, paginateNext, paginateBack } =
    usePaginatedProducts(children)
  const xPosition = `${-index * 100}%`
  const transform = `translateX(${xPosition})`
  const showArrows = numberOfProducts > 1

  return (
    /*
      Using chromatic ignore since chromatic cannot handle featured products currently.
      TODO: Figure out why
    */
    <div className={s.featured} data-chromatic="ignore">
      <div className={s.featured__products} style={{ transform }}>
        {children}
      </div>
      {showArrows && <Arrow onClick={paginateBack} direction="left" />}
      {showArrows && <Arrow onClick={paginateNext} direction="right" />}
      <Progress current={index} length={numberOfProducts} />
    </div>
  )
}

type ProductProps = {
  link: FiestaLinkProps
  title: string
  backgroundImage: string
  featuredText: string
  isCertified: boolean
  isMandatory: boolean
  mandatoryText: string
  showScore: boolean
  score: string
  scoreText: string
  stars: number
  starsMax: number
}
const Product = ({
  backgroundImage,
  title,
  link,
  featuredText,
  isCertified,
  isMandatory,
  mandatoryText,
  showScore,
  score,
  scoreText,
  stars,
  starsMax,
}: ProductProps) => (
  <FiestaLink
    className={s.product}
    style={backgroundImageStyle(backgroundImage)}
    {...link}
  >
    <div className={s.product__badges}>
      <div>{isCertified && <Certified />}</div>
      <div>{isMandatory && <Mandatory>{mandatoryText}</Mandatory>}</div>
    </div>
    <div className={s.product__gradient}>
      <div className={s.product__label}>{featuredText}</div>
      <div className={s.product__title}>{title}</div>
      {showScore && <Score score={score} scoreText={scoreText} />}
      <Stars starsMax={starsMax} stars={stars} />
    </div>
  </FiestaLink>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__featured} />
      <div className={s.skeleton__title} />
    </div>
  </Card>
)

FeaturedProducts.Product = Product
FeaturedProducts.Skeleton = Skeleton

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ children }: PropsWithChildren) => (
  <div className={s.mandatory}>{children}</div>
)

type ScoreProps = {
  score: string
  scoreText: string
}
const Score = ({ score, scoreText }: ScoreProps) => (
  <div className={s.product__score}>{`${score} ${scoreText}`}</div>
)

type ArrowProps = {
  direction: "left" | "right"
  onClick: () => void
}
const Arrow = ({ direction, onClick }: ArrowProps) => (
  <button
    className={modifiers(s, "arrow", direction)}
    onClick={onClick}
    title={`Navigate ${direction}`}
  >
    <img src={featuredArrowIcon} alt="" />
  </button>
)

type ProgressProps = {
  current: number
  length: number
}
const Progress = ({ current, length }: ProgressProps) => {
  const progressItems = times(length, (index) => (
    <ProgressItem isActive={index === current} key={index} />
  ))

  return <div className={s.progress}>{progressItems}</div>
}

type ProgressItemProps = {
  isActive: boolean
}
const ProgressItem = ({ isActive }: ProgressItemProps) => (
  <div className={modifiers(s, "progressItem", { isActive })} />
)

const usePaginatedProducts = (children: ReactNode | undefined) => {
  const [index, setIndex] = useState(0)
  const childArray = Children.toArray(children)
  const numberOfProducts = childArray.length

  const paginateBack = () => {
    if (index === 0) {
      setIndex(numberOfProducts - 1)
    } else {
      setIndex(index - 1)
    }
  }

  const paginateNext = () => {
    if (index === numberOfProducts - 1) {
      setIndex(0)
    } else {
      setIndex(index + 1)
    }
  }

  return { index, numberOfProducts, paginateBack, paginateNext }
}
