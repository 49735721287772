import create from "zustand"

type VuplexStore = {
  productPlayingId: number | undefined
  setProductPlayingId: (productId: number | undefined) => void
}

export const useVuplexStore = create<VuplexStore>((set) => ({
  productPlayingId: undefined,
  setProductPlayingId: (productPlayingId) => set({ productPlayingId }),
}))
