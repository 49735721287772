import {
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase } from "./types"

type Options<Fn extends FnBase, TData> = Omit<
  UseQueryOptions<FnReturn<Fn>, unknown, TData>,
  "context" | "queryKey" | "queryFn"
>

export type UseQueries<Fn extends FnBase> = <TData = FnReturn<Fn>>(
  args: Parameters<Fn>[],
  options?: Options<Fn, TData>
) => UseQueryResult<TData>[]

export const getUseQueries = <Functions extends FunctionsBase>(
  { functions }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): UseQueries<FnBase> => {
  return (argList, options) => {
    const fn = functions[functionName]!

    return useQueries({
      queries: argList.map((args) => ({
        queryKey: [queryPrefix, functionName, ...args],
        queryFn: () => fn(...args),
        ...(options as {}), // TODO: find a non-hacky way to make typescript happy
      })),
    })
  }
}
