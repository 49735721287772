import { ReactComponent as ChevronIcon } from "assets/icons/chevronIcon.svg"
import medalIcon from "assets/icons/medalIcon.svg"
import { PropsWithChildren, ReactNode } from "react"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { Card } from "../../base/Card"
import s from "./LeaderboardView.module.scss"

export const LeaderboardView = ({ children }: PropsWithChildren) => (
  <Card borderRadius="32">
    <div className={s.view}>{children}</div>
  </Card>
)

type ScopeSelectProps = {
  value: string
  onChange: (value: string) => void
  header: string
  children: ReactNode
}
const Scopes = ({ children, value, onChange, header }: ScopeSelectProps) => (
  <div className={s.scopeSelect}>
    <div className={s.scopeSelect__wrapper}>
      <img src={medalIcon} alt="" />
      <div className={s.scopeSelect__text}>{header}</div>
    </div>
    <select
      onChange={(value) => onChange(value.target.value)}
      value={value}
      className={s.scopeSelect__select}
    >
      {children}
    </select>
  </div>
)

type ScopeProps = {
  value: string
  children: ReactNode
}
const Scope = ({ value, children }: ScopeProps) => (
  <option value={value}>{children}</option>
)

const Leaderboards = ({ children }: PropsWithChildren) => (
  <div className={s.view__leaderboards}>{children}</div>
)

type LeaderboardProps = {
  link: FiestaLinkProps
  linkText: string
  title: string
  children: ReactNode
}
const Leaderboard = ({ link, title, children, linkText }: LeaderboardProps) => (
  <div className={s.leaderboard}>
    <div className={s.leaderboard__header}>
      <div className={s.leaderboard__title}>{title}</div>
      <FiestaLink className={s.leaderboard__link} {...link}>
        <div className={s.leaderboard__linkText}>{linkText}</div>
        <ChevronIcon />
      </FiestaLink>
    </div>
    <div className={s.leaderboard__entries}>{children}</div>
  </div>
)

const Skeleton = () => (
  <Card>
    <div className={s.skeleton} />
  </Card>
)

LeaderboardView.Scopes = Scopes
LeaderboardView.Scope = Scope
LeaderboardView.Leaderboards = Leaderboards
LeaderboardView.Leaderboard = Leaderboard
LeaderboardView.Skeleton = Skeleton
