import { ReactNode } from "react"
import { custom } from "src/bonzai/bonzai"
import { Loader, WidgetLayout } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { KpiSalesWidgetLoader } from "./KpiSalesWidgetLoader"
import { LeaderboardWidgetLoader } from "./LeaderboardWidgetLoader"
import { NewsWidgetLoader } from "./NewsWidgetLoader"
import { RecentActivityWidgetLoader } from "./RecentActivityWidgetLoader"
import { SubHeadersLoader } from "./SubHeadersLoader"
import { UserWidgetLoader } from "./UserWidgetLoader"

type WidgetLayoutLoaderProps = {
  children: ReactNode
  showUserInfoHeader?: boolean
}
export const WidgetLayoutLoader = ({
  children,
  showUserInfoHeader,
}: WidgetLayoutLoaderProps) => (
  <>
    <SubHeadersLoader showUserInfoHeader={showUserInfoHeader} />
    <WidgetLayout widgets={<WidgetsLoader />}>
      <QueryBoundary fallback={<Loader />}>{children}</QueryBoundary>
    </WidgetLayout>
  </>
)

const WidgetsLoader = () => {
  const flags = custom.getPortalConfigurations.useQuery()

  return (
    <>
      {<UserWidgetLoader />}
      {flags.enable_portal_news && <NewsWidgetLoader />}
      {!flags.disable_leaderboard && <LeaderboardWidgetLoader />}
      {flags.enable_kpi_sales_tiles && <KpiSalesWidgetLoader />}
      {flags.enable_activity_feed && <RecentActivityWidgetLoader />}
    </>
  )
}
