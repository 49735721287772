import { get, set } from "idb-keyval"

export const getLocaleFromStorage = (company: string) => {
  return get<string>(getStorageKey(company))
}

export const saveLocaleInStorage = (company: string, locale: string) => {
  return set(getStorageKey(company), locale)
}

const getStorageKey = (company: string) => {
  const STORAGE_KEY = "PORTAL_LOCALE"
  return `${STORAGE_KEY}:${company}`
}
