import { SetDataOptions } from "@tanstack/react-query"
import produce from "immer"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = SetDataOptions
type Return<Fn extends FnBase> = [
  readonly unknown[],
  FnReturn<Fn> | undefined
][]
type Arguments<Fn extends FnBase> = Partial<Parameters<Fn>>
type Updater<Fn extends FnBase> =
  | FnReturn<Fn>
  | ((oldData: FnReturn<Fn>) => FnReturn<Fn> | undefined | void)

export type SetQueriesData<Fn extends FnBase> = {
  (args: Arguments<Fn>, updater: Updater<Fn>, options?: Options): Return<Fn>
  (updater: Updater<Fn>, options?: Options): Return<Fn>
}

export const getSetQueriesData = <Functions extends FunctionsBase>(
  { queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): SetQueriesData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const [updater, options] = rest
    const key = [queryPrefix, functionName, ...args]

    return queryClient.setQueriesData(
      key,
      typeof updater === "function" ? wrapUpdater(updater) : updater,
      options
    )
  }
}

const wrapUpdater = (updater: any) => {
  return produce((data) => {
    const isDataLoading = data === undefined
    if (isDataLoading) return

    return updater(data)
  })
}
