/* 
  toFormattedNumber
  It formats a number based on the locale.
  
  Examples:

  Number: 10000    |  Number: 10000    
  Locale: en-US    |  Locale: nb-NO
  -----------------+-----------------
  Result: 10,000   |  Result: 10 000 
*/
export const formatNumber = (locale: string, number: number) => {
  return new Intl.NumberFormat(locale).format(number)
}
