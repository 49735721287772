import { useScrollToTopOnMount } from "hooks"

export const NotFoundPage = () => {
  useScrollToTopOnMount()

  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "600",
        fontSize: "4rem",
        margin: "4rem",
      }}
    >
      404
    </div>
  )
}
