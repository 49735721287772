import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import playIcon from "assets/icons/playIcon.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { PropsWithChildren } from "react"
import { modifiers } from "tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { Card } from "../../base/Card"
import { ProgressBar } from "../../base/ProgressBar"
import s from "./JourneyCard.module.scss"

type Props = {
  activityProgress: number
  activityProgressMax: number
  image: string
  link: FiestaLinkProps
  productType: string
  stars: number
  starsMax: number
  title: string

  isActivityProgressVisible: boolean
  isCertified: boolean
  isFastProduct: boolean
  isImageVisible: boolean
  isInProgress: boolean
  isMandatory: boolean
  isProductTypeVisible: boolean
  isStarsVisible: boolean

  inProgressText: string
  mandatoryText: string
}

export const JourneyCard = (props: Props) => {
  const {
    link,
    isMandatory,
    isCertified,
    isStarsVisible,
    isProductTypeVisible,
    isImageVisible,
    isActivityProgressVisible,
  } = props

  return (
    <Card>
      <FiestaLink {...link} className={modifiers(s, "card", { isMandatory })}>
        {isImageVisible && <Image {...props} />}
        <Bottom>
          <RowOne>
            <Title {...props} />
            {isCertified && <Certified />}
          </RowOne>
          <RowTwo>
            {isStarsVisible && <Stars {...props} />}
            {isProductTypeVisible && <ProductType {...props} />}
          </RowTwo>
          {isActivityProgressVisible && <ActivityProgress {...props} />}
        </Bottom>
        {isMandatory && <Mandatory {...props} />}
      </FiestaLink>
    </Card>
  )
}

const Bottom = ({ children }: PropsWithChildren) => (
  <div className={s.bottom}>{children}</div>
)

const RowOne = ({ children }: PropsWithChildren) => (
  <div className={s.rowOne}>{children}</div>
)

const RowTwo = ({ children }: PropsWithChildren) => (
  <div className={s.rowTwo}>{children}</div>
)

const Title = ({ title }: Props) => <div className={s.title}>{title}</div>

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Image = ({ image }: Props) => (
  <div className={s.image}>
    <img className={s.image__image} src={image} alt="" />
    <img className={s.image__play} src={playIcon} alt="" />
  </div>
)

const Stars = ({ starsMax, stars }: Props) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}

const ProductType = ({
  productType,
  isInProgress,
  inProgressText,
  isFastProduct,
}: Props) => (
  <div className={modifiers(s, "productType", { isFastProduct })}>
    {productType} {isInProgress && ` (${inProgressText})`}
  </div>
)

const ActivityProgress = ({
  activityProgress,
  activityProgressMax,
  isCertified,
  isMandatory,
}: Props) => (
  <div className={modifiers(s, "progress", { isMandatory })}>
    <ProgressBar
      value={activityProgress ?? 0}
      maxValue={activityProgressMax ?? 100}
      color={isCertified ? "green-30" : "blue-40"}
    />
  </div>
)

const Mandatory = ({ mandatoryText }: Props) => (
  <div className={s.mandatory}>{mandatoryText}</div>
)
