import { useLayoutEffect } from "react"

const IS_TEST_BUILD = process.env.NODE_ENV === "test"

export const useScrollToTopOnMount = () => {
  useLayoutEffect(() => {
    if (IS_TEST_BUILD) return

    window.scrollTo(0, 0)
  }, [])
}
