import { ButtonHTMLAttributes, forwardRef } from "react"
import { MateriaBorderRadius } from "../../../types/MateriaBorderRadius"
import { getCssBorderRadius } from "../../../utils/getCssValues"
import s from "./Button.module.scss"

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  borderRadius?: MateriaBorderRadius
}
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ borderRadius, ...props }, ref) => (
    <button
      type="button"
      className={s.button}
      style={{ borderRadius: getCssBorderRadius(borderRadius) }}
      ref={ref}
      {...props}
    />
  )
)
