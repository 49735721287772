import { vuplexMessageListener } from "./messageListener"
import { sendVuplexMessage } from "./sendVuplexMessage"

export const initializeVuplex = () => {
  if ("vuplex" in window) {
    addEventListener()
  } else {
    window.addEventListener("vuplexready", addEventListener)
  }
}

const addEventListener = () => {
  window.vuplex!.addEventListener("message", (event) => {
    vuplexMessageListener(event.data)
  })

  sendVuplexMessage({ type: "READY" })
}
