import { exists } from "@attensi/utils"

export type Locale = {
  languageCode: string
  regionCode?: string
}

export const parseLocale = (localeString: string): Locale => {
  const [languageCode, regionCode] = localeString.split(/-|_/)

  return {
    languageCode: languageCode!.toLowerCase(),
    regionCode: regionCode?.toUpperCase(),
  }
}

export const localeToString = (locale: Locale): string => {
  return [locale.languageCode, locale.regionCode].filter(exists).join("-")
}

export const isPartiallyEqual = (left: Locale, right: Locale) => {
  return left.languageCode === right.languageCode
}

export const isExactlyEqual = (left: Locale, right: Locale) => {
  return (
    left.languageCode === right.languageCode &&
    left.regionCode === right.regionCode
  )
}
