import checkmarkCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { PropsWithChildren } from "react"
import { modifiers } from "tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { ProductCard } from "../ProductCard/ProductCard"
import s from "./ProductCardEvent.module.scss"

type ProductCardEventProps = {
  link: FiestaLinkProps
  info: string
  title: string
  month: string | undefined
  day: string | undefined
  isCertified: boolean
  isMandatory: boolean
  isAttending: boolean
  mandatoryText: string
  showDate: boolean
}
export const ProductCardEvent = ({
  link,
  info,
  title,
  isMandatory,
  isCertified,
  isAttending,
  mandatoryText,
  month,
  day,
  showDate = true,
}: ProductCardEventProps) => (
  <ProductCard.Shell>
    <FiestaLink
      className={modifiers(s, "card", { isCertified, isAttending })}
      {...link}
    >
      {isCertified && <Certified />}
      {isMandatory && <Mandatory>{mandatoryText}</Mandatory>}
      <div className={s.card__info}>{info}</div>
      {showDate && (
        <div className={s.card__date}>
          <div className={s.card__month}>{month}</div>
          <div className={s.card__day}>{day}</div>
        </div>
      )}
      <div className={s.card__title}>{title}</div>
      <div className={s.card__stars}>
        <Stars starsMax={1} stars={isCertified ? 1 : 0} />
      </div>
    </FiestaLink>
  </ProductCard.Shell>
)

const Certified = () => (
  <img className={s.certified} src={checkmarkCircleGreen} alt="" />
)

const Mandatory = ({ children }: PropsWithChildren) => (
  <div className={s.mandatory}>{children}</div>
)

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const getSrc = (isFilled: boolean) => (isFilled ? starFilled : starEmpty)

  const starElements = times(starsMax, (index) => (
    <img src={getSrc(index < stars)} alt="" key={index} />
  ))

  return <div className={s.stars}>{starElements}</div>
}
