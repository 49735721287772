import { ReactComponent as PlayButton } from "assets/icons/playButton.svg"
import starEmpty from "assets/icons/starEmpty.svg"
import starFilled from "assets/icons/starFilled.svg"
import { times } from "lodash-es"
import { ReactNode } from "react"
import { ProgressCircle } from "../../base/ProgressCircle"
import s from "./ProductPlaying.module.scss"

type ProductPlayingProps = {
  onClick: () => void
  children: ReactNode
}
export const ProductPlaying = ({ onClick, children }: ProductPlayingProps) => (
  <button className={s.productPlaying} onClick={onClick}>
    {children}
  </button>
)

type ImageProps = {
  src: string
}
const Image = ({ src }: ImageProps) => (
  <img className={s.image} src={src} alt="" />
)

type TitleProps = {
  title: string
}
const Title = ({ title }: TitleProps) => <div className={s.title}>{title}</div>

type StarsProps = {
  stars: number
  starsMax: number
}
const Stars = ({ starsMax, stars }: StarsProps) => {
  const starElements = times(starsMax, (index) => (
    <img
      key={index}
      className={s.stars__star}
      src={index < stars ? starFilled : starEmpty}
      alt=""
    />
  ))

  return <div className={s.stars}>{starElements}</div>
}

type PlayProps = {
  progress: number
  progressMax: number
}
const Play = ({ progress, progressMax }: PlayProps) => (
  <div className={s.play}>
    <div className={s.play__progress}>
      <ProgressCircle
        value={progress}
        maxValue={progressMax}
        color="coal"
        backgroundColor="marble"
        stroke={7}
      />
    </div>
    <PlayButton className={s.play__playIcon} />
  </div>
)

ProductPlaying.Image = Image
ProductPlaying.Title = Title
ProductPlaying.Play = Play
ProductPlaying.Stars = Stars
