import { createContext } from "react"

/*
  In mobile view, we want the action buttons inside the modal to be placed in the bottom of the screen.
  This is done with a react portal. It allows us to render a component in a different place in the DOM.
  https://reactjs.org/docs/portals.html

  To let the action buttons know where it teleport to, we use context to communicate a target DOM node.
  https://reactjs.org/docs/context.html
*/

type ActionButtonsContextType = {
  targetNode: HTMLDivElement | null
} | null

export const ActionButtonsContext =
  createContext<ActionButtonsContextType>(null)
