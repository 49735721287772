import { useEffect, useLayoutEffect, useRef, useState } from "react"
import s from "./Loader.module.scss"

type LoaderProps = {
  isVisible?: boolean
  delay?: number
}
export const Loader = ({ isVisible = true, delay = 0 }: LoaderProps) => {
  const isWaiting = useDelay(delay)

  if (!isVisible || isWaiting) return null

  return (
    <div className={s.loader}>
      <LoadingAnimation />
    </div>
  )
}

const LoadingAnimation = () => {
  const ref = useSyncedAnimation()
  return <div className={s.loader__spinner} ref={ref} />
}

const keyframes: Keyframe[] = [
  { transform: "rotate(0deg)" },
  { transform: "rotate(360deg)" },
]

const animationOptions: KeyframeAnimationOptions = {
  duration: 1000,
  iterations: Number.POSITIVE_INFINITY,
}

let isAnimated = true

export const disableLoadingAnimation = () => {
  isAnimated = false
}

let existingAnimation: Animation

const useSyncedAnimation = () => {
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const element = ref.current
    if (!element || !isAnimated || !element.animate) return

    const animation = element.animate(keyframes, animationOptions)

    if (existingAnimation?.currentTime) {
      animation.currentTime = existingAnimation.currentTime
    }
    existingAnimation = animation

    return () => {
      setTimeout(() => animation.cancel(), 0)
    }
  }, [])

  return ref
}

const useDelay = (delay: number) => {
  const [isWaiting, setIsWaiting] = useState(delay > 0)

  useEffect(() => {
    if (delay === 0) return

    const timeout = setTimeout(() => {
      setIsWaiting(false)
    }, delay)
    return () => clearTimeout(timeout)
  })

  return isWaiting
}
