import { QueryFilters } from "@tanstack/react-query"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = QueryFilters
type Return<Fn extends FnBase> = FnReturn<Fn> | undefined

export type GetQueryData<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args: Parameters<Fn>, options?: Options): Return<Fn>
      (options?: Options): Return<Fn>
    }
  : (args: Parameters<Fn>, options?: Options) => Return<Fn>

export const getGetQueryData = <Functions extends FunctionsBase>(
  { queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): GetQueryData<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)

    const key = [queryPrefix, functionName, ...args]

    return queryClient.getQueryData(key, ...rest)
  }
}
