import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { v1 } from "src/bonzai/bonzai"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { TEXT_FALLBACK } from "src/constants"
import { getPaginationData } from "src/dataUtilities/getPaginationData"
import { ForYouView, ProductList } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"
import { ProductCardLoader } from "./ProductCardLoader"
import { ProductCardsSkeleton } from "./ProductCardsSkeleton"

type Category = v1["getProductCategories"]["data"][number]

type ProductCategoryProductsLoaderProps = {
  category: Category
}
export const ProductCategoryLoader = (
  props: ProductCategoryProductsLoaderProps
) => (
  <QueryBoundary fallback={<ForYouView.CategorySkeleton />}>
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ category }: ProductCategoryProductsLoaderProps) => {
  const { t } = useTranslation()
  const productsData = useData(category.id)
  const { data, isFetching, fetchNextPage } = productsData
  const { nextPageCount, remainingCount } = getPaginationData(data)
  const hasMoreItems = remainingCount > 0

  const productPages = data.pages.map((page, index) => {
    const isLastPage = index === data.pages.length - 1
    const hideLastCard = hasMoreItems && isLastPage
    const slicedProducts = hideLastCard ? page.data.slice(0, -1) : page.data

    const productCards = slicedProducts.map((product) => (
      <ProductCardLoader
        key={product.id}
        productId={product.id}
        productImage={product.image}
        productTitle={product.title ?? TEXT_FALLBACK} // TODO: use pickText with v3
        productType={product.identifier}
        isScorable={product.scorable}
        apiVersion="v1"
      />
    ))

    return <Fragment key={index}>{productCards}</Fragment>
  })

  const showMoreCount = hasMoreItems ? remainingCount + 1 : remainingCount
  const skeletonCount = hasMoreItems ? nextPageCount + 1 : nextPageCount
  const showMoreText = `${t("product.SHOW_MORE")} (${showMoreCount})`

  return (
    // TODO: use pickText with v3
    <ForYouView.Category title={category.title ?? TEXT_FALLBACK}>
      <ProductList>
        {productPages}
        {isFetching && <ProductCardsSkeleton count={skeletonCount} />}
        {hasMoreItems && !isFetching && (
          <ProductList.ShowMore onClick={fetchNextPage} text={showMoreText} />
        )}
      </ProductList>
    </ForYouView.Category>
  )
}

const useData = (category_id: number) => {
  const { i18n } = useTranslation()

  return v1.getProductsInCategory.useInfiniteQuery(
    [{ category_id, locale: i18n.language, per_page: 6 }],
    { getNextPageParam: getNextPageParamV1 }
  )
}
