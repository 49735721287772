import { queryParams } from "@attensi/utils"
import { v1, v3 } from "src/bonzai/bonzai"
import { DEVICE_PLATFORM } from "src/constants"
import { getInstallAppLink } from "src/dataUtilities/productDataUtilities"
import { APP_ENV } from "src/env"
import { ConfigError } from "src/tools/ConfigError"
import { authApi } from "tools"

type Product = v1["getProduct"]
type Platform = Product["supported_platforms"][number]

export const getPlayLinks = async (productId: number) => {
  const product = await getProduct(productId)

  const [startAppLink, launcherLink, installAppLink, webGLLink] =
    await Promise.all([
      getStartAppLink(product),
      getLauncherLink(product),
      getInstallAppLink(product),
      getWebGLLink(product),
    ])

  return { startAppLink, launcherLink, installAppLink, webGLLink }
}

const getStartAppLink = async (product: Product) => {
  const { platform, isAppPlatform } = DEVICE_PLATFORM

  if (!isAppPlatform) return
  if (!isPlatformSupported(product, platform)) return

  return addParams(product.content_link, product, platform)
}

const getLauncherLink = async (product: Product) => {
  const { platform, isLauncherPlatform } = DEVICE_PLATFORM

  if (!isLauncherPlatform) return
  if (!isPlatformSupported(product, platform)) return

  return addParams(product.launcher_link, product, platform)
}

const getWebGLLink = async (product: Product) => {
  const { isAppPlatform } = DEVICE_PLATFORM

  if (isAppPlatform) return
  if (!isPlatformSupported(product, "WebGL")) return

  return addParams(product.webgl_content_link, product, "WebGL")
}

const addParams = async (
  link: string,
  product: Product,
  platform: Platform
) => {
  const { accessToken } = await authApi.getActiveSession()
  const portalRedirectPath = getPortalRedirectPath(product)

  const { api_token, company } = await getParamsForProduct(product.id, platform)
  if (api_token === undefined) return

  const params = {
    api_token,
    product: product.token,
    company: company.name_key,
    env: APP_ENV,
    portalRedirectPath,
    accessToken,
  }

  return queryParams(link, params)
}

const getPortalRedirectPath = (product: Product) => {
  if (product.product_type === "skill") {
    return window.location.pathname
  }
}

const getParamsForProduct = async (product_id: number, platform: Platform) => {
  const { data } = await v3.getProduct.fetchQuery(
    [product_id, { include: ["oauth_applications", "company"] }],
    { staleTime: Number.POSITIVE_INFINITY }
  )

  const app = data.oauth_applications?.find((app) => app.platform === platform)
  const api_token = app?.api_token

  const company = data.company

  if (!company) {
    throw new ConfigError("Product is missing company")
  }

  return { api_token, company }
}

const isPlatformSupported = (product: Product, platform: Platform) => {
  return product.supported_platforms.includes(platform)
}

const getProduct = (productId: number) => {
  return v1.getProduct.fetchQuery([productId], {
    staleTime: Number.POSITIVE_INFINITY,
  })
}
