import { getQueryParams } from "@attensi/utils"
import { assert, enums, optional, string, type } from "superstruct"
import { showErrorPopup } from "tools"

const AppParams = type({
  app_type: optional(enums(["portal", "skills"] as const)),
  app_version: optional(string()),
})

export const getAppParams = () => {
  const params = getQueryParams()
  try {
    assert(params, AppParams)
    return params
  } catch (error) {
    showErrorPopup({ error })
    return {}
  }
}
