import { useScrollToTopOnMount, useWindowWidth } from "hooks"
import { LeaderboardViewCompactLoader } from "./LeaderboardViewCompactLoader"
import { LeaderboardViewLoader } from "./LeaderboardViewLoader"

export const LeaderboardViewResponsive = () => {
  useScrollToTopOnMount()
  const { isSmallWindow } = useWindowWidth()

  return isSmallWindow ? (
    <LeaderboardViewCompactLoader />
  ) : (
    <LeaderboardViewLoader />
  )
}
