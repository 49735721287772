import { MateriaColor } from "../../../types/MateriaColor"
import { getCssColor } from "../../../utils/getCssValues"
import s from "./ProgressBar.module.scss"

type ProgressBarProps = {
  value: number
  color?: MateriaColor
  backgroundColor?: MateriaColor
  maxValue?: number
}
export const ProgressBar = ({
  value,
  maxValue = 100,
  color = "blue-40",
  backgroundColor,
}: ProgressBarProps) => (
  <div
    className={s.progressBar}
    style={{
      backgroundColor: getCssColor(backgroundColor),
    }}
  >
    <div
      className={s.progressBar__progress}
      style={{
        transform: `scaleX(${value / maxValue})`,
        backgroundColor: getCssColor(color),
      }}
    />
  </div>
)
