import { assert, Struct } from "superstruct"
import { QueryParams, wrapFetch, WrapFetchProps } from "wrap-fetch"
import { getConfig } from "../apiConfig"

export const get = <T>(
  schema: Struct<T>,
  path: string,
  params?: QueryParams
) => {
  return v1Fetch(schema, { method: "GET", path, params })
}

export const post = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch(schema, { method: "POST", path, data })
}

export const patch = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch(schema, { method: "PATCH", path, data })
}

export const del = <T>(schema: Struct<T>, path: string, data: any) => {
  return v1Fetch(schema, { method: "DELETE", path, data })
}

const v1Fetch = async <T>(
  schema: Struct<T>,
  options: Omit<WrapFetchProps<T>, "transform" | "pathPrefix" | "headers">
) => {
  const { getHeaders, apiBaseUrl, onError } = await getConfig()

  return wrapFetch({
    pathPrefix: "/api/v1",
    transform: (response) => transformResponse(schema, response),
    headers: await getHeaders?.(),
    baseUrl: apiBaseUrl,
    onError,
    ...options,
  })
}

const transformResponse = async <T>(schema: Struct<T>, response: string) => {
  const data = await JSON.parse(response)
  assert(data, schema)
  return data
}
