import icalGenerator from "ical-generator"
import { v1, v3 } from "src/bonzai/bonzai"
import { pickTextAsync } from "src/i18n/usePickText"
import { IS_APP } from "src/vuplex/constants"
import { sendVuplexMessage } from "src/vuplex/sendVuplexMessage"

type EventDate = v3["getProductCourse"]["data"]
type Product = v1["getProduct"]

export const addToCalendar = async (product: Product, eventDate: EventDate) => {
  if (IS_APP) {
    addToCalendarVuplex(product, eventDate)
  } else {
    await addToCalendarIcal(product, eventDate)
  }
}

const addToCalendarVuplex = async (product: Product, eventDate: EventDate) => {
  sendVuplexMessage({
    type: "ADD_TO_CALENDAR",
    payload: {
      title: await pickTextAsync(product.titles),
      start_datetime: eventDate.start_datetime,
      end_datetime: eventDate.end_datetime,
      location: eventDate.location,
      description: await pickTextAsync(product.descriptions, ""),
    },
  })
}

const addToCalendarIcal = async (product: Product, eventDate: EventDate) => {
  const cal = icalGenerator()

  cal.createEvent({
    summary: await pickTextAsync(product.titles),
    start: eventDate.start_datetime,
    end: eventDate.end_datetime,
    location: eventDate.location,
    description: await pickTextAsync(product.descriptions, ""),
  })

  const url = cal.toURL()

  window.location.href = url
}
