import { initializeApi } from "api"
import { initializeLocalise } from "lokalise"
import React from "react"
import ReactDOM from "react-dom/client"
import { authApi, initializeGlobalErrorPopup } from "tools"
import { App } from "./App"
import { prefetchImportantData } from "./bonzai/prefetchImportantData"
import {
  API_BASE_URL,
  API_TOKEN,
  AUTH_URL,
  IS_DEV_BUILD,
  LOGIN_URL,
} from "./env"
import { initializeI18n } from "./i18n/initializeI18n"
import { initializeSentry } from "./tools/initializeSentry"
import { initializeVuplex } from "./vuplex/initializeVuplex"

initializeGlobalErrorPopup()

initializeI18n()

initializeVuplex()

initializeLocalise({
  project: "portal",
})

prefetchImportantData()

initializeSentry()

initializeGlobalErrorPopup()

authApi.initializeAuth({
  authUrl: AUTH_URL,
  loginUrl: LOGIN_URL,
  backendUrl: API_BASE_URL,
  backendApiToken: API_TOKEN,
  subdomain: authApi.getSubdomainFromPath(),
})

initializeApi({
  apiBaseUrl: API_BASE_URL,
  getUserId: async () => {
    const session = await authApi.getActiveSession()
    return session.userId
  },
  getCompanyNameKey: async () => {
    const session = await authApi.getActiveSession()
    return session.companyNameKey
  },
  getHeaders: async () => {
    if (IS_DEV_BUILD) {
      // await wait(5000)
      // await wait(Math.random() * 5000)
    }

    const { accessToken } = await authApi.getActiveSession()
    return {
      Authorization: "Bearer " + accessToken,
      "API-Token": API_TOKEN,
    }
  },
  onError: async (error) => {
    if (error.status === 401) {
      await authApi.refreshLogin()
    }
  },
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
