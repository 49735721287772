import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { authApi } from "tools"
import {
  APP_ENV,
  IS_DEV_BUILD,
  SENTRY_URL,
  VERCEL_GIT_COMMIT_SHA,
} from "../env"

export const initializeSentry = async () => {
  if (IS_DEV_BUILD) return

  Sentry.init({
    dsn: SENTRY_URL,
    environment: APP_ENV,
    tracesSampleRate: 1,
    release: VERCEL_GIT_COMMIT_SHA,
    integrations: [new BrowserTracing()],
  })

  const { userId, companyNameKey, subdomain } = await authApi.getActiveSession()

  Sentry.setUser({ id: String(userId) })

  Sentry.setContext("Company", {
    name_key: companyNameKey,
    subdomain,
  })

  Sentry.setTags({
    company_name_key: companyNameKey,
  })
}
