import closeIcon from "assets/icons/closeIcon.svg"
import { ReactComponent as LikeIcon } from "assets/icons/likeIcon.svg"
import { PropsWithChildren } from "react"
import { FiestaLink } from "utility-components"
import { sanitizeHtml } from "../../../utils/sanitizeHtml"
import { Loader } from "../../base/Loader"
import { NewsCardLikeButton } from "../NewsCard/NewsCard"
import s from "./NewsItemView.module.scss"

export const NewsItemView = ({ children }: PropsWithChildren) => (
  <div className={s.view}>{children}</div>
)

type ContentProps = {
  image: string
  title: string
  description: string
}
const Content = ({ image, title, description }: ContentProps) => (
  <div className={s.content}>
    <img className={s.content__image} src={image} alt="" />
    <div className={s.content__title}>{title}</div>
    <div
      className={s.content__description}
      dangerouslySetInnerHTML={sanitizeHtml(description, "allow-links")}
    />
  </div>
)

type BottomProps = {
  numberOfLikes: number
  displayDate: string
  onLike: () => void
  likeText: string
  isLiked: boolean
}
const Bottom = ({
  numberOfLikes,
  displayDate,
  onLike,
  likeText,
  isLiked,
}: BottomProps) => (
  <div className={s.bottom}>
    <NewsCardLikeButton isLiked={isLiked} onClick={onLike} text={likeText} />
    <div className={s.bottom__likes}>
      <LikeIcon />
      {numberOfLikes}
    </div>
    <div className={s.bottom__date}>{displayDate}</div>
  </div>
)

type CloseButtonProps = {
  closeRoute: string
}
const CloseButton = ({ closeRoute }: CloseButtonProps) => (
  <FiestaLink className={s.closeButton} to={closeRoute}>
    <img className={s.closeButton__icon} src={closeIcon} alt="Close" />
  </FiestaLink>
)

const Skeleton = () => (
  <div className={s.view}>
    <div className={s.content}>
      <Loader />
    </div>
  </div>
)

NewsItemView.Content = Content
NewsItemView.Bottom = Bottom
NewsItemView.CloseButton = CloseButton
NewsItemView.Skeleton = Skeleton
