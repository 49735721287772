import { exists } from "@attensi/utils"
import { last } from "lodash-es"
import { useEffect, useState } from "react"

const IS_TEST_BUILD = process.env.NODE_ENV === "test"

/*
  Maximum screen width for each size
  Must match the breakpoints defined in breakpoints.scss
*/
const BREAKPOINT_SMALL = 728
const BREAKPOINT_MEDIUM = 1024

const WINDOW_WIDTHS = ["small", "medium", "big"] as const
type WINDOW_WIDTH = typeof WINDOW_WIDTHS[number]

const mediaQuery = ({ min, max }: { min?: number; max?: number }) => {
  const minQuery = min ? `(min-width: ${min}px)` : undefined
  const maxQuery = max ? `(max-width: ${max - 1}px)` : undefined

  return ["screen", minQuery, maxQuery].filter(exists).join(" and ")
}

const MEDIA_QUERIES: Record<WINDOW_WIDTH, string> = {
  small: mediaQuery({ max: BREAKPOINT_SMALL }),
  medium: mediaQuery({ min: BREAKPOINT_SMALL, max: BREAKPOINT_MEDIUM }),
  big: mediaQuery({ min: BREAKPOINT_MEDIUM }),
}

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(() => detectWindowWidth())

  useEffect(() => watchBreakpoints(setWindowWidth), [])

  return {
    windowWidth,
    isSmallWindow: windowWidth === "small",
    isMediumWindow: windowWidth === "medium",
    isBigWindow: windowWidth === "big",
  }
}

const detectWindowWidth = (): WINDOW_WIDTH => {
  if (IS_TEST_BUILD) return "big"

  const matchingWidths = WINDOW_WIDTHS.filter(
    (width) => getMatchMedia(width).matches
  )
  return last(matchingWidths) ?? "small"
}

const watchBreakpoints = (onChange: (width: WINDOW_WIDTH) => void) => {
  if (IS_TEST_BUILD) return

  const listeners = WINDOW_WIDTHS.map((width) => {
    const listener = (e: MediaQueryListEvent) => {
      if (e.matches) onChange(width)
    }

    const media = getMatchMedia(width)
    media.addEventListener("change", listener)

    return () => media.removeEventListener("change", listener)
  })

  return () => {
    for (const unsubscribe of listeners) {
      unsubscribe()
    }
  }
}

const getMatchMedia = (width: WINDOW_WIDTH) => {
  return window.matchMedia(MEDIA_QUERIES[width])
}
