import { useQueryParam, useScrollToTopOnMount } from "hooks"
import { useTranslation } from "react-i18next"
import { v1 } from "src/bonzai/bonzai"
import { getNextPageParamV1 } from "src/bonzai/getNextPageParamV1"
import { FeaturedProductsLoader } from "src/components/FeaturedProductsLoader"
import { JourneyTeaserLoader } from "src/components/JourneyTeaserLoader"
import { ProductCategoryLoader } from "src/components/ProductCategoryLoader"
import { ForYouView } from "ui/exports/portal"
import { QueryBoundary, WhenVisible } from "utility-components"
import { FilterViewLoader } from "./FilterViewLoader"

export const ForYouViewLoader = () => {
  useScrollToTopOnMount()

  const [mandatoryQuery] = useQueryParam("mandatory")
  const [incompleteQuery] = useQueryParam("incomplete")
  const isMandatory = mandatoryQuery === "true"
  const isIncomplete = incompleteQuery === "true"

  if (isMandatory || isIncomplete) {
    return (
      <FilterViewLoader isMandatory={isMandatory} isIncomplete={isIncomplete} />
    )
  }

  return (
    <ForYouView>
      <QueryBoundary fallback={<ForYouView.Skeleton />}>
        <ProductCategoriesLoader />
      </QueryBoundary>
    </ForYouView>
  )
}

const ProductCategoriesLoader = () => {
  const categoriesData = useData()
  const { data, fetchNextPage, isFetching } = categoriesData

  const categories = data.pages.flatMap((page) => page.data)
  const categoryElements = categories.map((category) =>
    category.featured ? (
      <FeaturedProductsLoader category={category} key={category.id} />
    ) : (
      <ProductCategoryLoader category={category} key={category.id} />
    )
  )

  const teaser = <JourneyTeaserLoader key="teaser" />
  categoryElements.splice(1, 0, teaser)

  return (
    <>
      {categoryElements}
      {isFetching && <CategoriesSkeleton />}
      <WhenVisible whenVisible={fetchNextPage} key={data.pages.length} />
    </>
  )
}

const CategoriesSkeleton = () => (
  <>
    <ForYouView.CategorySkeleton />
    <ForYouView.CategorySkeleton />
    <ForYouView.CategorySkeleton />
  </>
)

const useData = () => {
  const { i18n } = useTranslation()

  return v1.getProductCategories.useInfiniteQuery(
    [{ locale: i18n.language, journey: false, per_page: 5 }],
    { getNextPageParam: getNextPageParamV1 }
  )
}
