import { useWindowWidth } from "hooks"
import { useTranslation } from "react-i18next"
import { v1 } from "src/bonzai/bonzai"
import { useFormatUser } from "src/bonzai/useFormatUser"
import { formatNumber } from "src/dataUtilities/formatNumber"
import { ProductModalLeaderboard } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

type ProductModalLeaderboardLoaderProps = {
  productId: number
}
export const ProductModalLeaderboardLoader = (
  props: ProductModalLeaderboardLoaderProps
) => (
  <QueryBoundary
    fallback={<ProductModalLeaderboard.Skeleton />}
    catchSuspense={useWindowWidth().isBigWindow}
  >
    <Load {...props} />
  </QueryBoundary>
)

const Load = ({ productId }: ProductModalLeaderboardLoaderProps) => {
  const { i18n } = useTranslation()

  const highScores = useData(productId)
  const { entries, my_entry } = highScores
  const formatUser = useFormatUser()

  const podium = entries
    .slice(0, 3)
    .map((entry, index) => (
      <ProductModalLeaderboard.PodiumItem
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(i18n.language, entry.value)}
      />
    ))

  const entryElements = entries
    .slice(3)
    .map((entry, index) => (
      <ProductModalLeaderboard.Entry
        key={index}
        image={entry.user.image}
        name={formatUser(entry.user)}
        rank={entry.rank}
        score={formatNumber(i18n.language, entry.value)}
      />
    ))

  const showMyEntry = my_entry.value > 0

  const myEntry = showMyEntry && (
    <ProductModalLeaderboard.Entry
      image={my_entry.user.image}
      name={formatUser(my_entry.user)}
      rank={my_entry.rank}
      score={formatNumber(i18n.language, my_entry.value)}
    />
  )

  return (
    <ProductModalLeaderboard>
      <ProductModalLeaderboard.Podium>{podium}</ProductModalLeaderboard.Podium>
      <ProductModalLeaderboard.Entries>
        {entryElements}
      </ProductModalLeaderboard.Entries>
      <ProductModalLeaderboard.MyEntry>
        {myEntry}
      </ProductModalLeaderboard.MyEntry>
    </ProductModalLeaderboard>
  )
}

const useData = (product_id: number) => {
  return v1.getHighScores.useQuery([
    { product_id, scope: "company", length: 7 },
  ])
}
