import { unknown } from "superstruct"
import {
  GetHighScoresParams,
  GetMandatoryProductsParams,
  GetMostPlayedProductsParams,
  GetProductCategoriesParams,
  GetProductsInCategoryParams,
  GetProductsInExploreParams,
  UpdateUserParams,
} from "./v1Params"
import {
  ActivityFeedResponse,
  AvatarsResponse,
  Company,
  DepartmentsResponse,
  EventDatesResponse,
  ExploreProductTypesResponse,
  HighScores,
  MandatoryProductsResponse,
  MostPlayedProductsResponse,
  PlayableProductIds,
  Product,
  ProductCategoriesResponse,
  ProductPlatformDetails,
  ProductsInCategoryResponse,
  StatusResponse,
  ToggleAttendingResponse,
  User,
  UserNameIsAvailableResponse,
  UserStatus,
  VppCodeResponse,
  VppCountriesResponse,
} from "./v1Responses"
import { del, get, patch, post } from "./v1Utils"

export const getCurrentCompany = () => {
  return get(Company, `/companies/current`)
}

export const getProduct = (productId: number) => {
  return get(Product, `/products/${productId}`)
}

export const getProductStatus = async (productId: number) => {
  return get(StatusResponse, `/products/${productId}/status`)
}

export const getMe = () => {
  return get(User, `/users/me`)
}

export const updateUser = (user: UpdateUserParams) => {
  return patch(User, `/users/me`, { user })
}

export const getIsUsernameAvailable = (userName: string) => {
  return get(UserNameIsAvailableResponse, `users/usernameavailable/${userName}`)
}

export const getAvatars = () => {
  return get(AvatarsResponse, `/avatars`)
}

export const getUserStatus = () => {
  return get(UserStatus, `/main/status`)
}

export const getActivityFeed = () => {
  return get(ActivityFeedResponse, `/frontend/activity-feed`)
}

export const getProductCategories = (params: GetProductCategoriesParams) => {
  const path = `/frontend/me/product-categories`
  return get(ProductCategoriesResponse, path, params)
}

export const getProductsInCategory = (params: GetProductsInCategoryParams) => {
  return get(ProductsInCategoryResponse, `/frontend/me/products`, params)
}

export const getProductsInExplore = (params: GetProductsInExploreParams) => {
  return get(ProductsInCategoryResponse, "frontend/me/products/explore", params)
}

export const getExploreProductTypes = () => {
  const url = "frontend/me/products/explore/identifiers"
  return get(ExploreProductTypesResponse, url)
}

export const getMandatoryProducts = (params: GetMandatoryProductsParams) => {
  const path = `/frontend/me/products/mandatory`
  return get(MandatoryProductsResponse, path, params)
}

export const getProductPlatformDetails = (
  productId: number,
  platform: string
) => {
  const path = `/frontend/me/products/${productId}`
  return get(ProductPlatformDetails, path, { platform })
}

export const getHighScores = (params: GetHighScoresParams) => {
  return get(HighScores, `/frontend/leaderboards/high_score`, params)
}

export const getMostPlayedProducts = (params: GetMostPlayedProductsParams) => {
  const url = `/leaderboards/most_played_product`
  return get(MostPlayedProductsResponse, url, params)
}

export const getPlayableProductIds = () => {
  return get(PlayableProductIds, "/frontend/me/products/playable_product_ids")
}

export const getDepartmentsBranch = () => {
  return get(DepartmentsResponse, "/users/me/departments/branch")
}

export const getEventDates = (productId: number) => {
  const url = `frontend/me/products/${productId}/courses`
  return get(EventDatesResponse, url)
}

export const setEventAttending = (eventId: number) => {
  const url = `frontend/me/courses/${eventId}/status`
  return post(ToggleAttendingResponse, url, null)
}

export const deleteEventAttending = (eventId: number) => {
  const url = `frontend/me/courses/${eventId}/status`
  return del(ToggleAttendingResponse, url, null)
}

export const createPlaythrough = (product_id: number) => {
  return post(unknown(), `/frontend/me/products/${product_id}/open`, {})
}

export const getVppCountries = (productId: number) => {
  const url = `products/${productId}/redeem/available_stores`
  return get(VppCountriesResponse, url)
}

export const getVppCode = (productId: number, store: string) => {
  const url = `products/${productId}/redeem/${store}`
  return get(VppCodeResponse, url)
}
