import {
  InvalidateOptions,
  InvalidateQueryFilters,
} from "@tanstack/react-query"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase } from "./types"
import { getArgs } from "./utils/getArgs"

type Options = InvalidateOptions
type Filters<Fn extends FnBase> = InvalidateQueryFilters<FnReturn<Fn>>
type Return = Promise<void>
type Arguments<Fn extends FnBase> = Partial<Parameters<Fn>>

export type InvalidateQueries<Fn extends FnBase> = {
  (args?: Arguments<Fn>, filters?: Filters<Fn>, options?: Options): Return
  (filters?: Filters<Fn>, options?: Options): Return
}

export const getInvalidateQueries = <Functions extends FunctionsBase>(
  { queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): InvalidateQueries<FnBase> => {
  return (...input) => {
    const { args, rest } = getArgs(input)
    const key = [queryPrefix, functionName, ...args]
    return queryClient.invalidateQueries(key, ...rest)
  }
}
