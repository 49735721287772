import { QueryClient } from "@tanstack/react-query"
import { showErrorPopup } from "tools"
import { isFetchError } from "wrap-fetch"

const onError = (error: unknown) => {
  showErrorPopup({ error })
}

const onRetry = (failureCount: number, error: unknown) => {
  const isNetworkOk = isFetchError(error) && error.status !== undefined

  if (isNetworkOk) return false
  if (failureCount < 3) return true
  return false
}

export const createQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        retry: onRetry,
        onError,
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
      },
      mutations: {
        onError,
      },
    },
  })
}
