import { times } from "lodash-es"
import { ProductCard } from "ui/exports/portal"

type ProductCardsSkeletonProps = {
  count: number
}
export const ProductCardsSkeleton = ({ count }: ProductCardsSkeletonProps) => {
  const skeletonCards = times(count, (index) => (
    <ProductCard.Skeleton key={index} />
  ))
  return <>{skeletonCards}</>
}
