import { Transition } from "@headlessui/react"
import { HTMLAttributes } from "react"
import s from "./MenuAnimation.module.scss"

type MenuAnimationProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean
}
export const MenuAnimation = ({ isOpen, ...props }: MenuAnimationProps) => (
  <Transition
    show={isOpen}
    enter={s["animation--enter"]}
    enterFrom={s["animation--enterFrom"]}
    enterTo={s["animation--enterTo"]}
    leave={s["animation--leave"]}
    leaveFrom={s["animation--leaveFrom"]}
    leaveTo={s["animation--leaveTo"]}
    {...props}
  />
)
