import type { ShowErrorPopupProps } from "@attensi/error-popup"

export const IS_DEV_BUILD = import.meta.env.DEV

export const showErrorPopup = async (props: ShowErrorPopupProps) => {
  try {
    const { showErrorPopup } = await import("@attensi/error-popup")

    showErrorPopup({ skipLandingPage: IS_DEV_BUILD, ...props })
  } catch (error) {
    console.error(error)
  } finally {
    console.error(props.error)
  }
}

export const initializeGlobalErrorPopup = () => {
  window.addEventListener("error", ({ error }) => {
    showErrorPopup({ error })
  })

  window.addEventListener("unhandledrejection", ({ reason }) => {
    showErrorPopup({ error: reason })
  })
}
