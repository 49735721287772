import { showErrorPopup } from "./errorPopup"

const prefix = "VITE_"

type StripPrefix<T extends string, Prefix extends string> =
  T extends `${Prefix}${infer Rest}` ? Rest : T

type Return<KEY extends string> = Record<
  StripPrefix<KEY, typeof prefix>,
  string
>

export const getEnvs = <KEY extends string>(keys: readonly KEY[]) => {
  const env = Object.fromEntries(
    keys.map((key) => {
      const trimmed = key.replace(prefix, "")
      const value = import.meta.env[key]
      return [trimmed, value] as const
    })
  )

  handleErrors(env)

  return env as Return<KEY>
}

const handleErrors = (env: Record<string, string | undefined>) => {
  let lastError: Error | undefined

  for (const key in env) {
    if (env[key] === undefined) {
      const error = new Error(`${key} is missing from environment`)
      showErrorPopup({ error, skipLandingPage: true })
      lastError = error
    }
  }

  if (lastError) throw lastError
}
