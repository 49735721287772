import crownIconGold from "assets/icons/crown.svg"
import crownIconBronse from "assets/icons/crownIconBronse.svg"
import crownIconSilver from "assets/icons/crownIconSilver.svg"
import { PropsWithChildren } from "react"
import { ProductModalShell } from "../ProductModalShell/ProductModalShell"
import s from "./ProductModalLeaderboard.module.scss"

export const ProductModalLeaderboard = ({ children }: PropsWithChildren) => (
  <ProductModalShell.Side>
    <div className={s.modal}>{children}</div>
  </ProductModalShell.Side>
)

const Podium = ({ children }: PropsWithChildren) => (
  <div className={s.modal__podium}>{children}</div>
)

type PodiumItemProps = {
  rank: number
  image: string
  name: string
  score: string
}
const PodiumItem = ({ rank, image, name, score }: PodiumItemProps) => (
  <div className={s.podiumItem}>
    <img className={s.podiumItem__crown} src={getCrownIcon(rank)} alt="" />
    <div className={s.podiumItem__imageWrapper}>
      <img className={s.podiumItem__image} src={image} alt="" />
      <div className={s.podiumItem__rank}>{rank}.</div>
    </div>
    <div className={s.podiumItem__name}>{name}</div>
    <div className={s.podiumItem__score}>{score}</div>
  </div>
)

const Entries = ({ children }: PropsWithChildren) => (
  <div className={s.modal__entries}>{children}</div>
)

const MyEntry = ({ children }: PropsWithChildren) => (
  <div className={s.modal__myEntry}>{children}</div>
)

type EntryProps = {
  rank: number
  image: string
  name: string
  score: string
}
const Entry = ({ rank, image, name, score }: EntryProps) => (
  <div className={s.entry}>
    <div className={s.entry__rank}>{rank}</div>
    <img className={s.entry__image} src={image} alt="" />
    <div className={s.entry__name}>{name}</div>
    <div className={s.entry__score}>{score}</div>
  </div>
)

const Skeleton = () => (
  <ProductModalShell.Side>
    <div className={s.skeleton} />
  </ProductModalShell.Side>
)

ProductModalLeaderboard.Podium = Podium
ProductModalLeaderboard.PodiumItem = PodiumItem
ProductModalLeaderboard.Entries = Entries
ProductModalLeaderboard.MyEntry = MyEntry
ProductModalLeaderboard.Entry = Entry
ProductModalLeaderboard.Skeleton = Skeleton

const getCrownIcon = (rank: number) => {
  if (rank === 1) return crownIconGold
  if (rank === 2) return crownIconSilver
  if (rank === 3) return crownIconBronse
}
