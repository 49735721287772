import starFilled from "assets/icons/starFilled.svg"
import userCompletedIcon from "assets/icons/userCompletedIcon.svg"
import { PropsWithChildren, ReactNode } from "react"
import { modifiers } from "tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { MateriaColor } from "../../../types/MateriaColor"
import { toPercent } from "../../../utils/toPercent"
import { Card } from "../../base/Card"
import { ProgressCircle } from "../../base/ProgressCircle/ProgressCircle"
import { Tooltip } from "../../base/Tooltip"
import s from "./UserWidget.module.scss"

/*
  TODO. Implement new design
  https://www.figma.com/file/y4WOhThlwtCQ103l7Va3pt/Incomplete-filter?node-id=207%3A142
*/

export const UserWidget = ({ children }: PropsWithChildren) => (
  <Card borderRadius="24">
    <div className={s.userWidget}>{children}</div>
  </Card>
)

type UserProps = {
  link: FiestaLinkProps
  name: string
  position: string
  avatar: string
}
const User = ({ link, avatar, name, position }: UserProps) => (
  <FiestaLink className={s.userWidget__user} {...link}>
    <img className={s.userWidget__avatar} src={avatar} alt="" />
    <div className={s.userWidget__name}>{name}</div>
    <div className={s.userWidget__position}>{position}</div>
  </FiestaLink>
)

const Progress = ({ children }: PropsWithChildren) => (
  <div className={s.progress}>{children}</div>
)

type CompletionProps = {
  progress: number
  progressMax: number
  completedText: string
}
const Completion = ({
  completedText,
  progress,
  progressMax,
}: CompletionProps) => (
  <ProgressContainer
    progress={progress}
    progressText={`${toPercent(progress, progressMax)}%`}
    progressMax={progressMax}
    text={completedText}
    color="green-30"
    backgroundColor="green-05"
    icon={userCompletedIcon}
  />
)

type ProgressTooltipProps = {
  title: string
  description: string
  children: ReactNode
  initiallyIsVisible?: boolean
}
const ProgressTooltip = ({
  title,
  description,
  children,
  initiallyIsVisible,
}: ProgressTooltipProps) => (
  <Tooltip initialIsVisible={initiallyIsVisible}>
    <Tooltip.Trigger>{children}</Tooltip.Trigger>
    <Tooltip.Content>
      <div className={s.progressTooltip}>
        <div className={s.progressTooltip__title}>{title}</div>
        <div className={s.progressTooltip__description}>{description}</div>
      </div>
    </Tooltip.Content>
  </Tooltip>
)

type StarsProps = {
  stars: number
  starsMax: number
  starsText: string
}
const Stars = ({ stars, starsMax, starsText }: StarsProps) => (
  <ProgressContainer
    progress={stars}
    progressMax={starsMax}
    progressText={`${stars}/${starsMax}`}
    text={starsText}
    color="yellow-50"
    backgroundColor="yellow-05"
    icon={starFilled}
  />
)

const Filters = ({ children }: PropsWithChildren) => (
  <div className={s.filters}>{children}</div>
)

type FilterProps = {
  link: FiestaLinkProps
  progress: string
  text: string
  color: "blue" | "pink"
  isActive: boolean
}
const Filter = ({ link, progress, text, color, isActive }: FilterProps) => (
  <FiestaLink {...link} className={modifiers(s, "filter", color, { isActive })}>
    <div className={s.filter__progress}>{progress}</div>
    <div className={s.filter__text}>{text}</div>
  </FiestaLink>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton} />
  </Card>
)

UserWidget.User = User
UserWidget.Progress = Progress
UserWidget.Completion = Completion
UserWidget.Stars = Stars
UserWidget.ProgressTooltip = ProgressTooltip
UserWidget.Filter = Filter
UserWidget.Filters = Filters
UserWidget.Skeleton = Skeleton

type ProgressContainerProps = {
  progress: number
  progressMax: number
  progressText: string
  text: string
  color: MateriaColor
  backgroundColor: MateriaColor
  icon: string
}
const ProgressContainer = ({
  progress,
  progressMax,
  progressText,
  text,
  icon,
  color,
  backgroundColor,
}: ProgressContainerProps) => (
  <div className={s.progressContainer}>
    <div className={s.progressContainer__radialContainer}>
      <ProgressCircle
        value={progress}
        color={color}
        maxValue={progressMax}
        backgroundColor={backgroundColor}
      />
      <img className={s.progressContainer__radialIcon} src={icon} alt="" />
    </div>
    <div className={s.progressContainer__number}>{progressText}</div>
    <div className={s.progressContainer__description}>{text}</div>
  </div>
)
