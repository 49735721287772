import newsColorIcon from "assets/icons/newsColorIcon.svg"
import { ReactNode } from "react"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { sanitizeHtml } from "../../../utils/sanitizeHtml"
import { Card } from "../../base/Card"
import s from "./NewsWidget.module.scss"

type NewsWidgetProps = {
  children: ReactNode
  headerText: string
}
export const NewsWidget = ({ children, headerText }: NewsWidgetProps) => (
  <Card borderRadius="24">
    <div className={s.widget}>
      <div className={s.widget__header}>
        <img className={s.widget__icon} src={newsColorIcon} alt="" />
        {headerText}
      </div>
      <div className={s.widget__items}>{children}</div>
    </div>
  </Card>
)

type ItemProps = {
  link: FiestaLinkProps
  title: string
  description: string
  image: string
}
const Item = ({ link, title, image, description }: ItemProps) => (
  <FiestaLink {...link} className={s.item}>
    <div className={s.item__title}>{title}</div>
    <img className={s.item__image} src={image} alt="" />
    <div
      className={s.item__description}
      dangerouslySetInnerHTML={sanitizeHtml(description, "strict")}
    />
  </FiestaLink>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton} />
  </Card>
)

NewsWidget.Item = Item
NewsWidget.Skeleton = Skeleton
