import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from "react"

type ContextType = ReturnType<typeof useValue>

const TopLevelContext = createContext<ContextType | null>(null)

export const PortalTarget = ({ children }: PropsWithChildren) => {
  const context = useValue()
  return (
    <TopLevelContext.Provider value={context}>
      {children}
      <div
        ref={context.ref}
        style={{ position: "relative", zIndex: 100 }}
        data-display-name="portal-target"
      />
    </TopLevelContext.Provider>
  )
}

export const usePortalTarget = () => {
  const context = useContext(TopLevelContext)

  if (context === null) {
    throw new Error(
      "usePortalTarget is used without a parent <PortalTarget />."
    )
  }

  return context
}

const useValue = () => {
  const ref = useRef<HTMLDivElement>(null)

  return useMemo(() => ({ ref }), [])
}
