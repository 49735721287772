import { ErrorBoundary } from "@sentry/react"
import { ReactNode, Suspense } from "react"
import { showErrorPopup } from "tools"
import { isFetchError } from "wrap-fetch"

type QueryBoundaryProps = {
  fallback?: ReactNode
  children?: ReactNode
  catchSuspense?: boolean
}
export const QueryBoundary = ({
  fallback,
  children,
  catchSuspense = true,
}: QueryBoundaryProps) => (
  <ErrorBoundary
    fallback={<></>}
    onError={(error) => showErrorPopup({ error })}
    beforeCapture={(scope, error) => {
      if (isFetchError(error)) {
        scope.setContext("Request", {
          method: error.method,
          url: error.url,
          params: error.params,
          status: error.status,
        })
      }
    }}
  >
    {catchSuspense ? (
      <Suspense fallback={fallback}>{children}</Suspense>
    ) : (
      children
    )}
  </ErrorBoundary>
)
