import { ERROR_NAME } from "./constants"
import { FetchError } from "./types"

type CreateFetchErrorProps = {
  message: string
  method: string
  url: string
  cause: Error | undefined
  status: number | undefined
  text: string | undefined
}

export const createFetchError = ({
  message,
  text,
  url,
  cause,
  method,
  status,
}: CreateFetchErrorProps): FetchError => {
  /*
    This is pretty weird and deserves an explanation.
    A normal Error won't serialize to JSON without loosing information, so it can't be easily logged without special treatment.
    The following javascript magic gives us an error that acts like a native Error but it can also be serialized to JSON.
  */
  const prototype = new Error(message)
  const error = Object.create(prototype)

  const fetchError: FetchError = {
    method,
    status,
    name: ERROR_NAME,
    message,
    url,
    cause,
    text,
    json: parseJson(text),
    params: parseParams(url),
    stack: cause?.stack ?? error.stack,
  }

  return Object.assign(error, fetchError)
}

const parseJson = (text: string | undefined): undefined | any => {
  if (text === undefined) return undefined

  try {
    return JSON.parse(text)
  } catch {
    return undefined
  }
}

const parseParams = (url: string | undefined) => {
  if (url === undefined) return undefined

  try {
    const entries = new URL(url).searchParams.entries()
    return Object.fromEntries(entries)
  } catch {
    return undefined
  }
}
