import { createResolvable } from "@attensi/utils"
import { FetchError, Headers } from "wrap-fetch"

export type ApiConfig = {
  apiBaseUrl: string
  getUserId: () => number | Promise<number>
  getCompanyNameKey: () => number | Promise<string>
  getHeaders?: () => Headers | Promise<Headers>
  onError?: (error: FetchError) => unknown | Promise<unknown>
}

export const { promise, resolve } = createResolvable<ApiConfig>()

export const initializeApi = (config: ApiConfig) => {
  resolve(config)
}

export const getConfig = () => {
  return promise
}

export const getUserid = async () => {
  const config = await getConfig()
  return config.getUserId()
}

export const getCompanyNameKey = async () => {
  const config = await getConfig()
  return config.getCompanyNameKey()
}
