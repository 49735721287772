import { literal, number, type, union } from "superstruct"

export const IncomingMessage = union([
  type({
    type: literal("SET_MINI_MODAL_PRODUCT_ID"),
    payload: number(),
  }),
  type({
    type: literal("REFRESH_PRODUCT_SCORE"),
  }),
])
