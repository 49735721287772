import checkIconCircleGreen from "assets/icons/checkmarkCircleGreen.svg"
import chevronIcon from "assets/icons/chevronIcon.svg"
import starFilled from "assets/icons/starFilled.svg"
import { modifiers } from "tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { backgroundImageStyle } from "../../../utils/backgroundImageStyle"
import { Card } from "../../base/Card"
import s from "./JourneyTeaser.module.scss"

type JourneyTeaserProps = {
  link: FiestaLinkProps
  backgroundImage: string
  header: string
  title: string
  certified: number
  certifiedMax: number
  stars: number
  starsMax: number
}
export const JourneyTeaser = ({
  link,
  backgroundImage,
  header,
  title,
  certified,
  certifiedMax,
  stars,
  starsMax,
}: JourneyTeaserProps) => (
  <Card borderRadius="24">
    <FiestaLink
      className={modifiers(s, "teaser")}
      style={backgroundImageStyle(backgroundImage)}
      {...link}
    >
      <div className={s.teaser__cardWrapper}>
        <div className={s.teaser__title}>
          <div className={s.teaser__titleText}>{title}</div>
          <img src={chevronIcon} className={s.teaser__chevronIcon} alt="" />
        </div>

        <div className={s.teaser__stars} aria-hidden>
          <img className={s.teaser__icon} src={starFilled} alt="" />
          {stars}/{starsMax}
        </div>
        <div className={s.teaser__certified} aria-hidden>
          <img src={checkIconCircleGreen} className={s.teaser__icon} alt="" />
          {certified}/{certifiedMax}
        </div>
      </div>
    </FiestaLink>
  </Card>
)

const Skeleton = () => (
  <Card borderRadius="24">
    <div className={s.skeleton}>
      <div className={s.skeleton__title} />
      <div className={s.skeleton__status} />
    </div>
  </Card>
)

JourneyTeaser.Skeleton = Skeleton
