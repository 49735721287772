import { forwardRef, InputHTMLAttributes } from "react"
import { modifiers } from "tools"
import s from "./Input.module.scss"

type InputProps = {
  label?: string
  infoText?: string
  disabled?: boolean
  errorMessage?: string
} & InputHTMLAttributes<HTMLInputElement>

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, infoText, disabled, errorMessage, ...props }, ref) => (
    <label className={modifiers(s, "input", { disabled })}>
      {label}

      <input
        ref={ref}
        className={s.input__input}
        disabled={disabled}
        {...props}
      />

      {errorMessage ? (
        <p className={s.input__error}>{errorMessage}</p>
      ) : (
        <p className={s.input__info}>{infoText}</p>
      )}
    </label>
  )
)
