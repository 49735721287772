import { InfiniteData } from "@tanstack/react-query"
import { last } from "lodash-es"

export const getPaginationData = (data: InfiniteData<any>) => {
  const lastPage = last(data?.pages)
  const { count = 0, current = 0, per_page = 0 } = lastPage?.pagination || {}

  const remainingCount = Math.max(count - current * per_page, 0)
  const nextPageCount = Math.min(remainingCount, per_page)
  const totalCount = count
  const currentCount = count - remainingCount

  return { nextPageCount, remainingCount, totalCount, currentCount }
}
