import { FetchQueryOptions } from "@tanstack/react-query"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = FetchQueryOptions<FnReturn<Fn>>
type Return<Fn extends FnBase> = Promise<FnReturn<Fn>>

export type FetchQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args?: Parameters<Fn>, options?: Options<Fn>): Return<Fn>
      (options?: Options<Fn>): Return<Fn>
    }
  : {
      (args: Parameters<Fn>, options?: Options<Fn>): Return<Fn>
    }

export const getFetchQuery = <Functions extends FunctionsBase>(
  { functions, queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): FetchQuery<FnBase> => {
  return (...input) => {
    const fn = functions[functionName]!
    const { args = [], rest } = getArgs(input)
    const key = [queryPrefix, functionName, ...args]
    return queryClient.fetchQuery(key, () => fn(...args), ...rest)
  }
}
