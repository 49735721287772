import { isPromise } from "./utils/isPromise"

let isRunningQueryAll = false

// This type will make TypeScript infer as a tuple instead of an array. I don't know why.
// https://github.com/vercel/swr/blob/3606785557ef75eb332c15f2918155be9802d46c/_internal/types.ts#L249
type Tuple = readonly [any, ...unknown[]]

export const getIsRunningQueryAll = () => isRunningQueryAll

/**
 * useQueryAll enables useQuery to run in parallell
 */
export const useQueryAll = <T extends Tuple>(runQueries: () => T): T => {
  try {
    isRunningQueryAll = true
    const queryResults = runQueries()

    const isSomeDataPending = queryResults.some(isPromise)
    if (isSomeDataPending) {
      throw Promise.all(queryResults)
    }

    return queryResults
  } finally {
    isRunningQueryAll = false
  }
}
