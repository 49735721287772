import { QueryClientProvider } from "@tanstack/react-query"
import { PortalTarget, QueryBoundary } from "utility-components"
import { AppRouter } from "./AppRouter"
import { queryClient } from "./bonzai/bonzai"
import { CompletedProductBannerLoader } from "./components/CompletedProductBannerLoader"

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <PortalTarget>
      <QueryBoundary>
        <AppRouter />
        <CompletedProductBannerLoader />
      </QueryBoundary>
    </PortalTarget>
  </QueryClientProvider>
)
