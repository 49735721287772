import { useCallback, useRef } from "react"

/*
  Returns a referencially stable function that always calls the newest handler.

  Check out the RFC for why it's useful https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
*/

type Handler = (...args: any[]) => void

export const useEvent = <T extends Handler>(
  handler: T
): ((...args: Parameters<T>) => void) => {
  const handlerRef = useRef<T>(handler)

  handlerRef.current = handler

  return useCallback((...args) => {
    handlerRef.current(...args)
  }, [])
}
