import { useTranslation } from "react-i18next"
import { v3 } from "src/bonzai/bonzai"
import { usePickText } from "src/i18n/usePickText"
import { NewsWidget } from "ui/exports/portal"
import { QueryBoundary } from "utility-components"

export const NewsWidgetLoader = () => (
  <QueryBoundary fallback={<NewsWidget.Skeleton />}>
    <Load />
  </QueryBoundary>
)

const Load = () => {
  const { t } = useTranslation()
  const { data } = v3.getNewsPosts.useQuery([{ "page[size]": 3 }])
  const pickText = usePickText()

  const items = data.map((item) => (
    <NewsWidget.Item
      link={{ to: `/news/post/${item.id}` }}
      key={item.id}
      title={pickText(item.headline)}
      description={pickText(item.body)}
      image={item.image?.file_url ?? ""}
    />
  ))

  return <NewsWidget headerText={t("news.NEWS")}>{items}</NewsWidget>
}
