import { FetchQueryOptions } from "@tanstack/react-query"
import { BonzaiProps, FnBase, FnReturn, FunctionsBase, NoArgFn } from "./types"
import { getArgs } from "./utils/getArgs"

type Options<Fn extends FnBase> = FetchQueryOptions<FnReturn<Fn>>
type Return = Promise<void>

export type PrefetchQuery<Fn extends FnBase> = Fn extends NoArgFn
  ? {
      (args?: Parameters<Fn>, options?: Options<Fn>): Return
      (options?: Options<Fn>): Return
    }
  : {
      (args: Parameters<Fn>, options?: Options<Fn>): Return
    }

export const getPrefetchQuery = <Functions extends FunctionsBase>(
  { functions, queryClient }: BonzaiProps<Functions>,
  queryPrefix: string,
  functionName: keyof Functions
): PrefetchQuery<FnBase> => {
  return (...input) => {
    const fn = functions[functionName]!
    const { args, rest } = getArgs(input)
    const key = [queryPrefix, functionName, ...args]
    return queryClient.prefetchQuery(key, () => fn(...args), ...rest)
  }
}
