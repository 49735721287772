import closeIconWhite from "assets/icons/closeIconWhite.svg"
import { ReactNode } from "react"
import { modifiers } from "tools"
import s from "./ViewerModal.module.scss"

type ViewerModalProps = {
  children: ReactNode
  onClose: () => void
}
export const ViewerModal = ({ children, onClose }: ViewerModalProps) => (
  <div className={s.viewerModal}>
    <div className={s.viewerModal__dimmer} onClick={onClose} />
    <div className={s.viewerModal__content}>{children}</div>
  </div>
)

type WebGLProps = {
  src: string
  orientation: "portrait" | "landscape"
  onClose: () => void
}
const WebGL = ({ src, orientation, onClose }: WebGLProps) => (
  <div className={modifiers(s, "webgl", orientation)}>
    <div className={s.webgl__closeButton}>
      <CloseButton onClick={onClose} />
    </div>
    <div className={s.webgl__content}>
      <Iframe src={src} />
    </div>
  </div>
)

type FullScreenProps = {
  headerText: string
  src: string
  onClose: () => void
}
const FullScreen = ({ headerText, src, onClose }: FullScreenProps) => (
  <div className={s.fullScreen}>
    <div className={s.fullScreen__header}>
      <div>{headerText}</div>
      <CloseButton onClick={onClose} />
    </div>
    <div className={s.fullScreen__content}>
      <Iframe src={src} />
    </div>
  </div>
)

ViewerModal.WebGL = WebGL
ViewerModal.FullScreen = FullScreen

type CloseButtonProps = {
  onClick: () => void
}
const CloseButton = ({ onClick }: CloseButtonProps) => (
  <button className={s.closeButton} onClick={onClick}>
    <img className={s.closeButton__icon} src={closeIconWhite} alt="Close" />
  </button>
)

type IframeProps = {
  src: string
}
const Iframe = ({ src }: IframeProps) => (
  <iframe
    className={s.iframe}
    src={src}
    title="Attensi product viewer"
    allow="autoplay; encrypted-media"
    allowFullScreen
  />
)
